import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Container, Form, Button } from "react-bootstrap";
import "./Footer.css";
import * as Fa from "react-icons/fa";
import Logo from "../../Assets/logo-home.png";
import * as Bs from "react-icons/bs";
import * as Ai from "react-icons/ai";
import { AppContext } from "../../Context/AppContext";

function Footer() {
  const { routes } = useContext(AppContext);
  const navigate = useNavigate();

  console.log("routes", routes);

  return (
    <>
      <div className="foo">
        <div className="form-top">
          <div className="ft-bar"></div>
          <br />
          <Container>
            <Row>
              <div className="col-lg-12 col-md-12 col-sm-12 footer1">
                <Row>
                  <div className="col-lg-4 col-md-4 col-sm-4 mx-auto">
                    <div className="logo-part mx-auto text-center">
                      <a href="/">
                        <img src={Logo} alt="" className="home-logo" />
                      </a>
                    </div>
                    {/* <div className="mx-auto text-center">
                      <div className="f_social">
                        <i className="fb">
                          <a href="https://www.facebook.com/" target="_blank">
                            <Fa.FaFacebookF />
                          </a>
                        </i>
                        <i className="yt">
                          <a href="https://www.youtube.com/" target="_blank">
                            <Bs.BsYoutube />
                          </a>
                        </i>
                        <i className="insta">
                          <a href="https://www.instagram.com/" target="_blank">
                            <Ai.AiFillInstagram />
                          </a>
                        </i>
                        <i className="twt">
                          <a href="https://www.twitter.com/" target="_blank">
                            <Bs.BsTwitter />
                          </a>
                        </i>
                      </div>
                    </div> */}

                    {/* <div className="address">
                      <p>
                        <i>
                          <Fa.FaLocationArrow />
                        </i>{" "}
                        26985 Brighton Lane, Lake Forest, CA 92630.
                      </p>
                      <p>
                        <i>
                          <Bs.BsFillTelephoneFill />
                        </i>{" "}
                        <a className="footer_contact" href="tel:01255 448888">
                          01255 448888
                        </a>
                      </p>
                      <p>
                        <i>
                          <Bs.BsFillTelephonePlusFill />
                        </i>{" "}
                        <a className="footer_contact" href="tel:07944 126272">
                          07944 126272
                        </a>
                        <span>(Admission Team)</span>
                      </p>
                      <p>
                        <i>
                          <Ai.AiFillMail />
                        </i>{" "}
                        <a
                          className="footer_contact"
                          href="mailto:trustedpayer@gmail.com"
                        >
                          trustedpayer@gmail.com
                        </a>
                      </p>
                    </div> */}
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="mx-auto text-center">
                      <h4 className="footer_sub-head">Information</h4>
                      <ul className="footer_ul">
                        {routes &&
                          routes.map((item, key) => {
                            let path = item?.route ? item?.route : "/";
                            if (item.published === true && item.route != '/') {
                              return (
                                <li className="footer_links" key={key}>
                                  <a
                                    onClick={() => navigate(path)}
                                    className="footer_link"
                                  >
                                    {item?.pageName}
                                  </a>
                                </li>
                              );
                            }
                          })}
                        <li className="footer_links">
                          <a
                            onClick={() => navigate("/register")}
                            className="footer_link"
                          >
                            Write a Review
                          </a>
                        </li>
                        {/* <li className="footer_links">
                          <a
                            onClick={() => navigate("/about")}
                            className="footer_link"
                          >
                            About Us
                          </a>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                  {/* <div className="col-lg-4 col-md-4 col-sm-4">
                    <div className="mx-auto text-center">
                      <h4 className="footer_sub-head">Support</h4>
                      <ul className="footer_ul">
                        <li className="footer_links">
                          <a href="/contact">FAQ</a>
                        </li>{" "}
                        <li className="footer_links">
                          <a
                            onClick={() => navigate("/contact")}
                            className="footer_link"
                          >
                            Contact
                          </a>
                        </li>
                        <li className="footer_links">
                          <a href="/">Privacy Policy</a>
                        </li>
                      </ul>
                    </div>
                  </div> */}
                </Row>
              </div>
            </Row>
          </Container>
        </div>
        {/* <div className="footer-btm">
          <div className="foo_line"></div>
          <Container>
            <p>Designed & Developed By Ferns-IT</p>
          </Container>
        </div> */}
      </div>
    </>
  );
}

export default Footer;
