import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import { AppContext } from "../../Context/AppContext";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../Components/Header/Header";
import * as Ai from "react-icons/ai";
import * as Hi from "react-icons/hi";
import * as Bs from "react-icons/bs";
import "./Register.css";
import toast, { Toaster } from "react-hot-toast";
import { PulseLoader } from "react-spinners";

// to avoid recreating it on each render
const debounce = (func, delay) => {
  let timeoutId;
  return function (...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(null, args);
    }, delay);
  };
};

function Register() {
  const { id, companyname } = useParams();
  const {
    searchBusinessName,
    complaintCategory,
    usersList,
    protestLoading,
    addNewProtest,
  } = useContext(AppContext);
  const [filterData, setFilterData] = useState([]);
  const [value, setValue] = useState([]);
  const [error, setError] = useState(false);
  const [initValues, setInitValues] = useState({
    businessProfile: "",
    protest: "",
    businessName: "",
    companyConnection: "",
    complaintCategory: "",
    title: "",
    anonymous: false,
    pdffile: null,
  });

  // useEffect(() => {
  //   setInitValues((prev) => ({ ...prev, user: usersList._id }));
  // }, []);

  useEffect(() => {
    handleParams();
  }, [companyname, id]);

  //handle Params value when it's available
  const handleParams = () => {
    if (!id && !companyname) return;
    setValue({
      businessProfile: id,
      businessName: companyname,
    });
    setInitValues((prevValues) => ({
      ...prevValues,
      businessProfile: id,
      businessName: companyname,
    }));
  };

  //callback for debounce Search
  const debouncedSearch = useCallback(
    debounce(async (value) => {
      const searchTerm = value;

      const payload = {
        searchTerm,
      };

      try {
        await searchBusinessName(payload, {
          onSuccess: (res) => {
            if (!Array.isArray(res.data)) return;
            setFilterData(res.data);
          },
          onFailed: (err) => {
            console.log("Error on Register", err.message);
          },
        });
      } catch (err) {
        console.log(err);
      }

      if (value == "") {
        setFilterData([]);
      }
    }, 100),
    []
  );

  //Filter as per Category
  const handleFilter = async (value) => {
    setValue(value);
    debouncedSearch(value);
  };

  //set the value to the field
  function handleCompanyFilter(e) {
    setValue(e);
    if (e) {
      setInitValues((prevValues) => ({
        ...prevValues,
        businessProfile: e._id,
        businessName: e.businessName,
      }));
    }
    setFilterData([]);
  }

  //reset the category to default
  const clearBusinessCategory = () => {
    const select = document.getElementById("review_categories");
    select.selectedIndex = 0;
  };

  const handleConnection = (e) => {
    if (["Employee", "Client", "Other", "Supplier"].includes(e)) {
      setInitValues((prev) => ({
        ...prev,
        companyConnection: e,
      }));
    } else {
      console.error("Invalid companyConnection value.");
      setError(true);
    }
  };

  //post form Submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      initValues.businessProfile === "" ||
      initValues.user === "" ||
      initValues.protest === "" ||
      initValues.businessName === "" ||
      initValues.companyConnection === "" ||
      initValues.complaintCategory === ""
    ) {
      setError(true);
      return;
    }

    const formData = new FormData();

    formData.append("businessProfile", initValues.businessProfile);
    formData.append("protest", initValues.protest);
    formData.append("businessName", initValues.businessName);
    formData.append("companyConnection", initValues.companyConnection);
    formData.append("complaintCategory", initValues.complaintCategory);
    formData.append("title", initValues.title);
    formData.append("anonymous", initValues.anonymous ? "true" : "false");

    if (initValues.pdffile != null) {
      formData.append("pdffile", initValues.pdffile);
    }

    await addNewProtest(formData, {
      onSuccess: (res) => {
        toast.success("Your review has been Registered SuccessFully!");

        setTimeout(() => {
          window.location.reload();
          setInitValues({
            businessProfile: "",
            user: "",
            protest: "",
            businessName: "",
            companyConnection: "",
            complaintCategory: "",
            pdffile: null,
            anonymous: false,
          });
        }, 1000);
      },
      onFailed: (err) => {
        toast.error(err.message);
      },
    });
  };

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <div className="container-fluid">
        <Header />
        <div className="container register_">
          <section className="register-protest">
            <div className="mx-auto">
              <div className="protest-container">
                <h4 className="protest_sub_head">Trusted Payer</h4>
                <h2 className="protest_header">Objective Review Submission</h2>
                <p className="warning">
                  Note : Please refrain from reloading the page before
                  submitting. Your progress may be lost, and your submission
                  might not go through successfully.
                </p>
                <div className="card register-card">
                  <div className="info_section">
                    <i>
                      <Bs.BsInfoCircleFill />
                    </i>
                    <p>
                      Understanding the Legal Implications:{" "}
                      <a href="#">Guidelines for Review</a>
                    </p>
                  </div>
                  <div className="container">
                    <form className="mt-2" onSubmit={(e) => handleSubmit(e)}>
                      <div className="row mt-2">
                        <div className="col-lg-6 col-md-12 col-sm-8">
                          <div className="form-group mb-2">
                            <label
                              htmlFor=""
                              className="form-label review-label"
                            >
                              Company Name <span className="required">*</span>
                            </label>
                            <div className="review-group">
                              <input
                                type="text"
                                className="form-control review-input"
                                name="fullName"
                                placeholder="Enter the Company Name"
                                autoComplete="off"
                                value={value?.businessName}
                                onChange={(e) => handleFilter(e.target.value)}
                              />
                              <i className="inp_ico">
                                <Hi.HiOutlineOfficeBuilding />
                              </i>
                            </div>
                            {error && initValues.businessName.length == 0 && (
                              <span className="err_msg">Select a Company!</span>
                            )}
                            {filterData.length != 0 && (
                              <div className="company_suggetions">
                                {filterData.map((company) => {
                                  return (
                                    <a
                                      onClick={() => {
                                        handleCompanyFilter(company);
                                      }}
                                    >
                                      <p>{company?.businessName ?? "N/A"}</p>
                                    </a>
                                  );
                                })}
                              </div>
                            )}
                          </div>
                        </div>
                        {/* End of Col */}
                        <div className="col-lg-6 col-md-12 col-sm-8">
                          <div className="form-group mb-2">
                            <label
                              htmlFor=""
                              className="form-label review-label"
                            >
                              Your Company Connection{" "}
                              <span className="required">*</span>
                            </label>
                            <div className="company-connection">
                              <div className="form-check">
                                <input
                                  className="form-check-input review-radio"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="Employee"
                                  value="Employee"
                                  onChange={(e) =>
                                    handleConnection(e.target.value)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  for="Employee"
                                >
                                  Employee
                                </label>
                              </div>
                              <div className="form-check mx-2">
                                <input
                                  className="form-check-input review-radio"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="Client"
                                  value="Client"
                                  onChange={(e) =>
                                    handleConnection(e.target.value)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  for="Client"
                                >
                                  Client
                                </label>
                              </div>
                              <div className="form-check mx-2">
                                <input
                                  className="form-check-input review-radio"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="Supplier"
                                  value="Supplier"
                                  onChange={(e) =>
                                    handleConnection(e.target.value)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  for="Supplier"
                                >
                                  Supplier
                                </label>
                              </div>
                              <div className="form-check mx-2">
                                <input
                                  className="form-check-input review-radio"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="Other"
                                  value="Other"
                                  onChange={(e) =>
                                    handleConnection(e.target.value)
                                  }
                                />
                                <label className="form-check-label" for="other">
                                  Other
                                </label>
                              </div>
                              {/* {showOtherInput == "other" && (
                                <input
                                  type="text"
                                  name=""
                                  id="otherInput"
                                  className="review-input review_inp2"
                                  placeholder="Name the connection"
                                  ref={textRef}
                                />
                              )} */}
                            </div>
                            {error &&
                              initValues.companyConnection.length == 0 && (
                                <span className="err_msg">
                                  Please select your connection with the
                                  company!
                                </span>
                              )}
                          </div>
                        </div>
                        {/* End of Col */}
                      </div>
                      {/* End of Row */}

                      <div className="row mt-2">
                        <div className="col-lg-6 col-sm-6 col-md-6">
                          <div className="form-group">
                            <label className="form-label review-label">
                              Review Category{" "}
                              <span className="required">*</span>
                            </label>
                            <div className="d-flex review_select_main">
                              <select
                                name=""
                                id="review_categories"
                                className="review-select form-control"
                                onChange={(e) =>
                                  setInitValues((prev) => ({
                                    ...prev,
                                    complaintCategory: e.target.value,
                                  }))
                                }
                              >
                                <option value="" id="" selected disabled hidden>
                                  Select a Category
                                </option>
                                {complaintCategory != null &&
                                  complaintCategory.map((complaint) => {
                                    return (
                                      <option value={complaint._id}>
                                        {complaint?.complaintCategoryName ??
                                          "N/A"}
                                      </option>
                                    );
                                  })}
                              </select>
                              {initValues.complaintCategory &&
                                initValues.complaintCategory.length > 0 &&
                                initValues.complaintCategory != "0" && (
                                  <button
                                    type="button"
                                    className="close_btn_"
                                    onClick={clearBusinessCategory}
                                  >
                                    <Ai.AiFillCloseCircle />
                                  </button>
                                )}
                            </div>
                            {error &&
                              initValues.complaintCategory.length == 0 && (
                                <span className="err_msg">
                                  Please Choose the Review category!
                                </span>
                              )}
                          </div>
                        </div>
                        {/* End of Col */}
                        <div className="col-lg-6 col-sm-6 col-md-6">
                          <label className="form-label review-label">
                            Documents/Invoice
                          </label>
                          <input
                            type="file"
                            name=""
                            id=""
                            className="form-control review-file"
                            onChange={(e) =>
                              setInitValues((prev) => ({
                                ...prev,
                                pdffile: e.target.files[0],
                              }))
                            }
                          />
                        </div>
                        {/* End of Col */}
                      </div>
                      {/* End of Row */}
                      <div className="row mt-2">
                        <div className="col-lg-6 col-sm-6 col-md-6">
                          <div className="form-group mb-2">
                            <label
                              htmlFor=""
                              className="form-label review-label"
                            >
                              Title <span className="required">*</span>
                            </label>
                            <div className="review-group">
                              <input
                                type="text"
                                className="form-control review-input"
                                name="title"
                                placeholder="Enter Title"
                                value={value?.title}
                                onChange={(e) =>
                                  setInitValues((prev) => ({
                                    ...prev,
                                    title: e.target.value,
                                  }))
                                }
                              />
                              <i className="inp_ico">
                                <Hi.HiOutlineOfficeBuilding />
                              </i>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="form-group">
                          <label htmlFor="" className="fomr-label review-label">
                            Review Brief <span className="required">*</span>
                          </label>
                          <textarea
                            name=""
                            id=""
                            cols="30"
                            rows="10"
                            className="review-area_ form-control"
                            placeholder="Enter your Brief"
                            value={initValues?.protest}
                            onChange={(e) =>
                              setInitValues((prev) => ({
                                ...prev,
                                protest: e.target.value,
                              }))
                            }
                          ></textarea>
                          {error && initValues.protest.length == 0 && (
                            <span className="err_msg">
                              Write your Review Before Posting!
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-lg-12 col-sm-12 col-md-12">
                          <div className="form-group">
                            <div className="">
                              <input
                                type="checkbox"
                                id="anonymous"
                                name="anonymous"
                                className="m-2"
                                checked={initValues?.anonymous}
                                onChange={(e) =>
                                  setInitValues((prev) => ({
                                    ...prev,
                                    anonymous: e.target.checked,
                                  }))
                                }
                              />
                              <label For="anonymous">
                                {" "}
                                Do you want to send this message anonymously?
                              </label>{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* End of Row */}
                      <button
                        type="submit"
                        className="review-btn review-submit my-3"
                        disabled={protestLoading}
                      >
                        {!protestLoading ? (
                          "Submit"
                        ) : (
                          <PulseLoader color="#fff" size={12} />
                        )}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Register;
