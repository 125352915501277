import React, { useState, useContext, useEffect, useRef } from "react";
import { NavLink, useLocation, Link, useNavigate } from "react-router-dom";
import { AppContext } from "../../Context/AppContext";
import { PulseLoader } from "react-spinners";
import Hamburger from "hamburger-react";
import Logo from "../../Assets/logo-home.png";
import * as Md from "react-icons/md";
import * as Fi from "react-icons/fi";
import "./Header.css";
import NavLinks from "../NavLinks";

function Header() {
  const navigate = useNavigate();
  const profileRef = useRef(null);
  const location = useLocation();
  const {
    cookieData,
    deleteCookieData,
    authLoading,
    usersList,
    loginData,
    routes,
  } = useContext(AppContext);
  const [isUrl, setIsUrl] = useState(null);
  const [click, setClick] = useState(false);
  const [navbar, setNavbar] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    setIsUrl(location.pathname);
  }, [location]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (profileRef.current && !profileRef.current.contains(event.target)) {
        setShowProfile(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClick = () => {
    setClick(!click);
    setShowDropdown(true);
  };

  const handleLogout = async () => {
    await deleteCookieData({
      onLogoutSuccess: () => {
        setTimeout(() => {
          window.location.reload();
          navigate("/");
        }, 1000);
      },
    });
  };

  return (
    <div>
      <div className={navbar ? "head-main scrolled" : "head-main"}>
        <div className="container d-flex">
          <div className="logo_area">
            <a href="/">
              <img src={Logo} className="logo-img" />
            </a>
          </div>
          <NavLinks routes={routes} isUrl={isUrl} click={click} />
          <div className="auth-links">
            {cookieData == null ? (
              <>
                <a
                  href="/login"
                  className="login-btn"
                  style={{ cursor: "pointer", userSelect: "none" }}
                >
                  Login
                </a>

                <a
                  href="/signup"
                  className="reg-link register"
                  style={{ cursor: "pointer", marginLeft: "20px" }}
                >
                  Register
                </a>
              </>
            ) : (
              <>
                <div className="profile">
                  <button
                    className="profile_avat"
                    onClick={() => setShowProfile(!showProfile)}
                    id="profile_btn"
                  >
                    <Fi.FiUser />
                  </button>
                  <div
                    className={
                      !showProfile ? "profile-menu" : "profile-menu show"
                    }
                    id="profile"
                    ref={profileRef}
                  >
                    <h5>{loginData?.fullName ?? "N/A"}</h5>
                    <p>{loginData?.email ?? "N/A"}</p>
                    <hr />
                    <div className="d-flex">
                      <button
                        className="logout-btn mb-2 mt-2"
                        onClick={handleLogout}
                      >
                        {!authLoading ? (
                          <>
                            <div className="logout_btn">
                              Logout <Md.MdLogout />
                            </div>
                          </>
                        ) : (
                          <div
                            class="spinner-border text-danger spinner-border-sm"
                            role="status"
                          ></div>
                        )}
                      </button>{" "}
                      <button
                        className="profile-nav"
                        onClick={() => navigate("/profile")}
                      >
                        Profile
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="nav-icon" onClick={handleClick}>
            <Hamburger />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
