import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function SkeltLoader({ count }) {
  return Array(count)
    .fill(0)
    .map((item) => (
      <>
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div className="explore-card">
            <div className="flex-1">
              <div className="boxe">
                <Skeleton circle width={80} height={80} />
              </div>
              <div className="boxed">
                <p className="comp-n">
                  <Skeleton width={200} />
                </p>
                {/* <p className="comp-a"><Skeleton width={100}/></p> */}
                <p className="">
                  <Skeleton
                    width={100}
                    count={2}
                    style={{ paddingLeft: "0px" }}
                  />
                </p>
              </div>
            </div>
            <div className="row">
              <p className="comp-d">
                <Skeleton height={30} />
              </p>
            </div>
            <div className="row details_">
              <div className="col-auto">
                <p id="review_info_">
                  <Skeleton circle width={20} height={20} />
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    ));
}

export default SkeltLoader;
