import React, { useState, useEffect, useContext } from "react";
import { Modal } from "antd";
import Utils from "../../Utils/Utils";
import * as Ai from "react-icons/ai";
import * as Ri from "react-icons/ri";
import toast, { Toaster } from "react-hot-toast";
import { AppContext } from "../../Context/AppContext";

function ProfileModal({ showModal, setShowModal, reviewData }) {
  const [reviewProtest, setReviewProtest] = useState("");

  const { addNewProtest, getAllUSerData, protestLoading, deleteReview } =
    useContext(AppContext);

  useEffect(() => {
    if (!reviewData) return;
    setReviewProtest(reviewData?.protest);
  }, [reviewData]);

  const updateReview = async (e) => {
    e.preventDefault();

    if (!reviewData) return;

    const payload = {
      reviewId: reviewData?._id,
      protest: reviewProtest,
    };

    await addNewProtest(payload, {
      onSuccess: async (res) => {
        toast.success("Review edited successfully!");
        await getAllUSerData();
        setShowModal(false);
      },
      onFailed: (err) => {
        console.log(err);
      },
    });
  };

  const deleteSingleReview = async () => {
    if (!reviewData) return;
    console.log("reached");
    await deleteReview(reviewData?._id, {
      onSuccess: async (res) => {
        toast.success("Review deleted successfully!");
        await getAllUSerData();
        setTimeout(() => {
          setShowModal(false);
        }, 1000);
      },
      onFailed: (err) => {
        console.log(err);
      },
    });
  };

  return (
    <div>
      <Toaster position="top-center" reverseOrder={false} />
      <Modal open={showModal} onCancel={() => setShowModal(false)}>
        <form onSubmit={(e) => updateReview(e)}>
          <h4>{reviewData?.businessName ?? "N/A"}</h4>
          <span className="complaint_category">
            {reviewData?.complaintCategory?.complaintCategoryName ?? "N/A"}
          </span>
          <br />
          <p className="date__">
            <Ai.AiFillCalendar />
            {"  "}
            {Utils.formatDate(reviewData?.createdAt)}
          </p>
          <textarea
            name=""
            id=""
            className="review_protest"
            value={reviewProtest}
            onChange={(e) => setReviewProtest(e.target.value)}
          />
          <button type="submit" className="save-btn" disabled={protestLoading}>
            <Ai.AiOutlineSave /> Save
          </button>

          <button
            className="delete-btn"
            type="button"
            onClick={() => deleteSingleReview()}
            disabled={protestLoading}
          >
            <Ri.RiDeleteBin6Line /> Delete
          </button>
        </form>
      </Modal>
    </div>
  );
}

export default ProfileModal;
