import React, { Suspense, useContext } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ProtectAuthRoute, ProtectRoute } from "./Routes/protector";
import { AppContext } from "./Context/AppContext";
import { SkeletonTheme } from "react-loading-skeleton";
import AppRoutes from "./Routes/routes";
import Loader from "./Components/Loader/Loader";
import Error from "./Pages/Error/Error";
import ScrollToTop from "./ScrollToTop";
import "./App.css";
import Banned from "./Components/Banned/Banned";

function App() {
  const { pageLoading, cookieError, bannedStatus } = useContext(AppContext);

  return (
    <>
      {!pageLoading && cookieError == null && !bannedStatus ? (
        <Suspense fallback={<Loader />}>
          <SkeletonTheme>
            <BrowserRouter>
              <ScrollToTop />
              <Routes>
                {AppRoutes.map((routes, key) => {
                  return !routes.isProtected && !routes.isProtectedAuth ? (
                    <Route
                      key={key}
                      path={routes.path}
                      element={routes.element}
                      exact
                    />
                  ) : !routes.isProtected && routes.isProtectedAuth ? (
                    <Route
                      key={key}
                      path={routes.path}
                      element={
                        <ProtectAuthRoute>{routes.element}</ProtectAuthRoute>
                      }
                      exact
                    ></Route>
                  ) : (
                    <Route
                      key={key}
                      path={routes.path}
                      element={<ProtectRoute>{routes.element}</ProtectRoute>}
                      exact
                    ></Route>
                  );
                })}
              </Routes>
            </BrowserRouter>
          </SkeletonTheme>
        </Suspense>
      ) : pageLoading && cookieError == null && !bannedStatus ? (
        <Loader />
      ) : !pageLoading && cookieError == null && bannedStatus ? (
        <Banned />
      ) : (
        <Error errorMsg={cookieError} errorTitle="Something Went Wrong" />
      )}
    </>
  );
}

export default App;
