import React, { useEffect, useState, createContext } from "react";
import useAuth from "../Hooks/useAuth";
import useUser from "../Hooks/useUser";
import useReviews from "../Hooks/useReviews";
import useCategories from "../Hooks/useCategories";
import useCompany from "../Hooks/useCompany";
import useContact from "../Hooks/useContact";
import useProtest from "../Hooks/useProtest";
import useProfile from "../Hooks/useProfile";

export const AppContext = createContext();

export const AppContextProvider = (props) => {
  const [routes, setRoutes] = useState([]);
  const {
    registerUser,
    forgotPassword,
    OtpVerification,
    resetUserPassword,
    authLoading,
    loginUser,
    getCookieData,
    cookieData,
    setCookieData,
    pageLoading,
    cookieError,
    setCookieError,
    deleteCookieData,
    resendOTP,
    forgotUserData,
  } = useAuth();

  const { getReviewList, reviews, reviewLoading } = useReviews();
  const { getAllBusiness, businessCategories } = useCategories();

  const {
    updateProfileDetails,
    userData,
    profileLoading,
    getAllUSerData,
    updatePassword,
    fetchLoginData,
    loginData,
    getAllInformation,
    contentLoading,
    content,
    bannedStatus,
    setBannedStatus,
  } = useProfile();

  const {
    getAllCompaniesList,
    companies,
    companyLoading,
    getCompanyProfileByCategory,
    searchBusinessName,
  } = useCompany();

  const { Contactus, contacts, loader } = useContact();

  const {
    getProtestComplaintsCategory,
    complaintCategory,
    protestLoading,
    addNewProtest,
    deleteReview,
  } = useProtest();

  useEffect(() => {
    getCookieData();
    getAllBusiness();
    getAllInformation();
    getAllCompaniesList();
    getProtestComplaintsCategory();
    getReviewList();

    if (cookieData) {
      getAllUSerData();
      fetchLoginData();
    }
  }, []);

  useEffect(() => {
    if (content && content.length !== 0) {
      const updatedContent = content.map((data) => {
        const { contents, __v, createdAt, _id, ...rest } = data;
        return rest;
      });

      setRoutes((prevRoutes) => [...prevRoutes, ...updatedContent]);
    }
  }, [content]);

  return (
    <AppContext.Provider
      value={{
        registerUser,
        getAllBusiness,
        Contactus,
        contacts,
        loader,
        companies,
        authLoading,
        loginUser,
        getCookieData,
        OtpVerification,
        updatePassword,
        resendOTP,
        resetUserPassword,
        cookieData,
        fetchLoginData,
        loginData,
        forgotPassword,
        setCookieData,
        pageLoading,
        cookieError,
        deleteCookieData,
        updateProfileDetails,
        userData,
        profileLoading,
        getAllUSerData,
        getReviewList,
        reviews,
        reviewLoading,
        getAllBusiness,
        businessCategories,
        getAllCompaniesList,
        companies,
        companyLoading,
        getCompanyProfileByCategory,
        searchBusinessName,
        complaintCategory,
        protestLoading,
        addNewProtest,
        forgotUserData,
        bannedStatus,
        setBannedStatus,
        deleteReview,
        setCookieError,
        getAllInformation,
        contentLoading,
        content,
        routes,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};
