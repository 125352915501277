import { useState } from "react";
import BaseClient from "../Helpers/BaseClient";
import { APIEndpoints } from "../Constants/APIEndpoints";

const useCategories = () => {
  const [businessCategories, setBusinessCategories] = useState([]);
  const [isLoading, setIsLoading] = useState({
    action: "",
    loading: false,
  });

  const getAllBusiness = async () => {
    try {
      setIsLoading({ action: "Get", loading: true });
      await BaseClient.get(
        APIEndpoints.getAllBusinessCategories,
        {
          onSuccess: (res) => {
            if (!Array.isArray(res.data)) return;
            setBusinessCategories(res.data);
          },
        },
        {
          onFailed: (err) => {
            return console.log(err);
          },
        }
      );
    } finally {
      setIsLoading({ action: "", loading: false });
    }
  };

  return {
    getAllBusiness,
    businessCategories,
    isLoading
  };
};

export default useCategories;
