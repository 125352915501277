import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import logoImage from "../../Assets/logo-home.png";
import RegiSlider from "../../Components/RegiSlider.js/RegiSlider";
import PhoneInput from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
import "./Signup.css";
import "react-phone-number-input/style.css";
import { PulseLoader } from "react-spinners";
import * as Ai from "react-icons/ai";
import Modal from "antd/es/modal/Modal";
import * as Bs from "react-icons/bs";
import useForm from "../../Hooks/useForms";
import useAuth from "../../Hooks/useAuth";
import toast, { Toaster } from "react-hot-toast";
import registerValidator from "../../Helpers/RegisterValidator";
import { useContext } from "react";
import { AppContext } from "../../Context/AppContext";

function Signup() {
  const navigate = useNavigate();
  const { OtpVerification, resendOTP } = useContext(AppContext);
  const { registerUser, authLoading } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState("");
  const initialValues = {
    fullName: "",
    email: "",
    password: "",
    termsAndCondtions: false,
  };
  const [passwordType, setPasswordType] = useState("password");
  const [phone, setPhone] = useState("");
  const [formValues, formErrors, formValidator, onChange] = useForm(
    { ...initialValues },
    (value) => registerValidator(value)
  );
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };

  const userRegistration = async () => {
    if (!formValues.termsAndCondtions) return;
    const { fullName, email, password, termsAndCondtions } = formValues;
    let payload = {
      fullName: fullName,
      email: email,
      phone: phone,
      password: password,
      termsAndCondtions: termsAndCondtions,
    };
    await registerUser(payload, {
      onSuccess: (res) => {
        toast.success("Registration SuccessFull! Please Verify the account!");
        window.sessionStorage.setItem("userId", res.data.id);
        window.sessionStorage.setItem("email", email);

        navigate("/otpverification/:verify");
      },
      onFailed: (err) => {
        if (err.errorCode === "VUO") {
          // setIsModalOpen(true);
          toast.success("Please verify your account and continue");
          window.sessionStorage.setItem("email", email);
          window.sessionStorage.setItem("userId", err.errormessage.userId);
          setTimeout(() => {
            navigate("/otpverification/:verify");
          }, 800);
        } else if (err.errorCode === "EAE") {
          toast.success("Email address already exist!");
        } else {
          toast.error(err.message);
        }
      },
    });
  };

  const handleUserRegistration = (e) => {
    e.preventDefault();
    formValidator();

    if (Object.keys(registerValidator(formValues).length === 0)) {
      userRegistration();
    }
  };
  const resendUserOTP = async () => {
    const userId = sessionStorage.getItem("userId");
    if (!userId) return;
    const payload = {
      userId,
    };
    await resendOTP(payload, {
      onSuccess: (res) => {
        toast.success("OTP Resended");
        setIsModalOpen(false);
        navigate("/otpverification/:verify");
      },
      onFailed: (error) => {
        toast.error(error.message);
        switch (error.errorCode) {
          case "USER_NOT_FOUND":
            setError("USER_NOT_FOUND");
            break;
          default:
            setError("UNEXPECTED ERROR");
        }
      },
    });
  };
  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <div className="container-fluid first-cont">
        <div className="container box-two p-0" style={{ overflow: "hidden" }}>
          <div className="row">
            <>
              <Modal open={isModalOpen}>
                <i onClick={handleCancel} className="end-bt">
                  <Ai.AiOutlineClose />
                </i>
                <div className="mx-auto text-center">
                  <p>Do you want to send an OTP to verify your account? </p>
                  <div className="text-center">
                    <button className="res-otp" onClick={resendUserOTP}>
                      Send
                    </button>
                  </div>
                </div>
              </Modal>
            </>

            <div className="col-lg-4 col-md-6 col-sm-6 show-col-md-4 p-4">
              <div style={{ textAlign: "center" }}>
                <img src={logoImage} alt="" className="logo-sign" />
              </div>
              <h4 className="text-center">Create Account</h4>
              <form
                className="signup-form "
                onSubmit={(e) => handleUserRegistration(e)}
              >
                <p className="para text-center">Enter details to continue</p>
                <div>
                  <div className="form-group mb-2">
                    <label htmlFor="" className="form-label review-label">
                      Name
                    </label>
                    <div className="review-group">
                      <input
                        type="text"
                        className="form-control review-input"
                        name="fullName"
                        placeholder="Full Name"
                        value={formValues.fullName}
                        onChange={onChange}
                        autoComplete="off"
                      />
                      <i className="inp_ico">
                        <Ai.AiOutlineUser />
                      </i>
                    </div>
                    {formErrors.fullName ? (
                      <p className="err_msg">{formErrors.fullName}</p>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="" className="form-label review-label">
                      Email Address
                    </label>
                    <div className="review-group">
                      <input
                        type="text"
                        className="form-control review-input"
                        name="email"
                        placeholder="Enter your Email Address"
                        value={formValues.email}
                        onChange={onChange}
                      />
                      <i className="inp_ico">
                        <Ai.AiOutlineMail />
                      </i>
                    </div>
                    {formErrors.email ? (
                      <p className="err_msg">{formErrors.email}</p>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="" className="form-label review-label">
                      Phone number
                    </label>
                    <div className="review-group">
                      <PhoneInput
                        name="phone"
                        international
                        defaultCountry="GB"
                        countrySelectProps={{ unicodeFlags: true }}
                        placeholder="Enter your phone number"
                        className="form-control review-input"
                        value={phone}
                        onChange={setPhone}
                        autoComplete="off"
                      />
                      <i className="inp_ico">
                        <Bs.BsTelephone />
                      </i>
                    </div>
                    {phone && phone.length == 0 ? (
                      <p className="err_msg">Phone Number is Required</p>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="" className="form-label review-label">
                      Password
                    </label>
                    <div className="review-group">
                      <input
                        className="form-control review-input"
                        type={passwordType}
                        placeholder="Enter Password"
                        name="password"
                        autoComplete="off"
                        value={formValues.password}
                        onChange={onChange}
                      />
                      <i
                        className="inp_ico"
                        id="pass-eye"
                        onClick={togglePassword}
                      >
                        {passwordType === "password" ? (
                          <Ai.AiOutlineEye />
                        ) : (
                          <Ai.AiOutlineEyeInvisible />
                        )}
                      </i>
                    </div>
                    {formErrors.password ? (
                      <p className="err_msg">{formErrors.password}</p>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="terms" id="terms">
                      {" "}
                      <input
                        type="checkbox"
                        name="termsAndCondtions"
                        id=""
                        className="review-check"
                        value={formValues.termsAndCondtions}
                        onChange={(e) =>
                          onChange({
                            target: {
                              name: e.target.name,
                              value: e.target.checked,
                            },
                          })
                        }
                      />{" "}
                      I Agree to the{" "}
                      <a href="#" className="termsc">
                        Terms & Conditions
                      </a>
                      .
                    </label>
                    {formErrors.termsAndCondtions ? (
                      <p className="err_msg">{formErrors.termsAndCondtions}</p>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="text-center">
                    <button className="signin mt-3" type="submit">
                      {authLoading ? (
                        <PulseLoader color="#fff" size={10} />
                      ) : (
                        "Register"
                      )}
                    </button>
                  </div>
                </div>
                <p className="text-center">
                  Already have an Account?{" "}
                  <a className="termsc navs" href="/login">
                    Login
                  </a>
                </p>
              </form>
            </div>
            <div className="col-lg-8 col-md-6 col-sm-6 hide-slider">
              <div className="slider-bg">
                <div className="card slider-card">
                  <RegiSlider />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Signup;
