import React, { useState } from "react";
import BaseClient from "../Helpers/BaseClient";
import { APIEndpoints } from "../Constants/APIEndpoints";

const useContact = () => {
  const [loader, setLoader] = useState(false);

  const contactUs = async (payload, { onSuccess, onFailed }) => {
    try {
      setLoader(true);
      await BaseClient.post(APIEndpoints.Contactus, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setLoader(false);
    }
  };

  return { contactUs, loader };
};

export default useContact;
