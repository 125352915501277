import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import { Toaster, toast } from "react-hot-toast";
import { AiFillLock } from "react-icons/ai";
import { BsPersonFillLock } from "react-icons/bs";
import Logo from "../../Assets/logo-home.png";
import { AppContext } from "../../Context/AppContext";
import * as Ai from "react-icons/ai";
import useAuth from "../../Hooks/useAuth";
import { message } from "antd";

function Password() {
  const navigate = useNavigate();
  const { resetUserPassword, updatePassword, loginData } =
    useContext(AppContext);

  const [loading, setLoading] = useState(false);

  const [inputValues, setInputValues] = useState({
    oldpassword: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setInputValues((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handlePassword = async (e) => {
    e.preventDefault();
    if (
      inputValues.password.length === 0 ||
      inputValues.oldpassword.length === 0
    ) {
      return;
    }

    const payload = inputValues;
    setLoading(true);
    await updatePassword(payload, {
      onSuccess: async (res) => {
        toast.success("Password Successfully Changed!");
        setTimeout(() => {
          navigate("/profile");
        }, 1000);
      },
      onFailed: (err) => {
        toast.error(err.message);
        setLoading(false);
      },
    });
  };
  const [passwordType, setPasswordType] = useState("password");

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  return (
    <div>
      <div className="container-fluid">
        <Toaster position="top-center" />
        <div className="login-form">
          <div className="text-center">
            <img src={Logo} alt="" className="company-im" />
          </div>

          <h4 className="title text-center">Change Password</h4>
          <form onSubmit={handlePassword}>
            <p className="para text-center"></p>
            <div className="form-group my-3">
              <label htmlFor="oldPassword" className="form-label review-label">
                Enter Current Password
              </label>
              <div className="review-group mb-3">
                <input
                  type="password"
                  className="form-control review-input"
                  name="oldpassword"
                  id="oldpassword"
                  value={inputValues.oldpassword}
                  onChange={handleChange}
                />
                <i className="inp_ico">
                  <AiFillLock />
                </i>
              </div>
              <label htmlFor="newPassword" className="form-label review-label">
                Enter New Password
              </label>
              <div className="review-group">
                <input
                  type={passwordType}
                  className="form-control review-input"
                  id="password"
                  name="password"
                  value={inputValues.password}
                  onChange={handleChange}
                />
                <i className="inp_ico" id="pass-eye" onClick={togglePassword}>
                  {passwordType === "password" ? (
                    <Ai.AiOutlineEye />
                  ) : (
                    <Ai.AiOutlineEyeInvisible />
                  )}
                </i>
              </div>
            </div>
            <p className="float-left nav-go">
              <Link to="/" className="navs">
                Go Home?
              </Link>
            </p>
            <div className="text-center">
              <button type="submit" className="sub-bt" disabled={loading}>
                {loading ? <PulseLoader color="#fff" size={10} /> : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Password;
