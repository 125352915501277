const registerValidator = (values) => {
  const errors = {};
  const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  if (!values.fullName) {
    errors.fullName = "Full Name is Required";
  }
  if (!values.email) {
    errors.email = "Email is Required";
  } 
  if (!values.phone) {
    errors.phone = "Phone Number is Required";
  }
  if (!values.password) {
    errors.password = "Password is Required";
  }
  if (values.password.length < 6) {
    errors.password = "Password must be more than 6 characters";
  }
  if (!values.termsAndCondtions) {
    errors.termsAndCondtions =
      "You Must Accept Our Terms & Conditions Before Registration";
  }
  return errors;
};

export default registerValidator;
