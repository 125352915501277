import React, { useContext, useState } from "react";
import Logo from "../../Assets/logo-home.png";
import "./Reset.css";
import * as Ai from "react-icons/ai";
import toast, { Toaster } from "react-hot-toast";
import * as Bs from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import { AppContext } from "../../Context/AppContext";
import useAuth from "../../Hooks/useAuth";

function Changepassword() {
  
  const navigate = useNavigate();
  const { resetUserPassword } = useContext(AppContext);
  const [password, setPassword] = useState(null);
  const [confirm, setConfirm] = useState(null);
  const { authLoading } = useAuth();
  const userDataJSON = sessionStorage.getItem("userData");
  const userData = userDataJSON ? JSON.parse(userDataJSON).data : null;
  const handlePassword = async (e) => {
    e.preventDefault();
    const userId = sessionStorage.getItem("userId");
    if (password !== confirm) {
      toast.error("Password do not match");
      return;
    }
    const payload = {
      userId: userData._id,
      password: password,
    };
    await resetUserPassword(payload, {
      onSuccess: (res) => {
        toast.success("Successfully Changed password");
        setTimeout(() => {
          navigate("/login");
        }, 1500);
      },
      onFailed: (err) => {
        console.log(err.message);
      },
    });
  };

  return (
    <div>
      <div className="container-fluid">
        <Toaster position="top-center" />
        <div className="login-form">
          <div className="text-center">
            <img src={Logo} alt="" className="company-im" />
          </div>

          <h4 className="title text-center">Reset Password</h4>
          <form onSubmit={handlePassword}>
            <p className="para text-center">Change Password </p>
            <div className="form-group my-3">
              <label htmlFor="" className="form-label review-label">
                New Password
              </label>
              <div className="review-group mb-3">
                <input
                  type="text"
                  className="form-control review-input"
                  name="password"
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter new Password"
                />
                <i className="inp_ico">
                  <Ai.AiFillLock />
                </i>
              </div>
              <label htmlFor="" className="form-label review-label">
                Confirm Password
              </label>
              <div className="review-group">
                <input
                  type="password"
                  className="form-control review-input"
                  name="confirm"
                  onChange={(e) => setConfirm(e.target.value)}
                  placeholder="Re-enter new Password"
                />
                <i className="inp_ico">
                  <Bs.BsPersonFillLock />
                </i>
              </div>
            </div>
            <p className="float-left nav-go">
              <a href="/" className="navs">
                Go Home ?
              </a>
            </p>
            <div className="text-center">
              <a className="white-a" href="">
                <button type="submit" className="sub-bt" disabled={authLoading}>
                  {authLoading ? (
                    <PulseLoader color="#fff" size={10} />
                  ) : (
                    "Submit"
                  )}
                </button>
              </a>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Changepassword;
