import React from "react";
import { useNavigate } from "react-router-dom";
import "./ReviewCard.css";
import * as Ai from "react-icons/ai";
import * as Bi from "react-icons/bi";
import * as Fi from "react-icons/fi";
import * as Bs from "react-icons/bs";
import * as Md from "react-icons/md";
import * as Io from "react-icons/io";
import * as Ri from "react-icons/ri";
import Aos from "aos";

function CardReview(props) {
  Aos.init();
  const navigate = useNavigate();

  const handleNavigation = () => {
    sessionStorage.setItem(
      "selectedCompany",
      JSON.stringify(props.selectedCompany)
    );
    navigate(`/${props.navigateLink}`);
  };

  return (
    <div>
      <div className="card review-card" data-aos="fade-up" data-aos-delay="100">
        <div className="verified_customer_section">
          <div className="image_review">
            <div className="customer_image">
              <img
                src={props.companyImage}
                alt="customer"
                className="rounded-img"
              />
            </div>

            <div className="customer_name_review_status">
              <p className="comp-name">{props.companyName}</p>
              <p className="comp_category">
                <span>Review Category</span> {props.complaintCategory}
              </p>
              <div className="customer-review">{props.businessCategory}</div>
              <div className="company-info_ text-center">
                {props.pdffile != null && (
                  <a
                    className="card_info_btn"
                    href={process.env.REACT_APP_AWS_IMAGE_URL + "pdffiles/" + props.pdffile}
                    target="_blank"
                  >
                    <Io.IoIosAttach /> Attachment
                  </a>
                )}
                {/* <button className="card_info_btn">
                  <Ai.AiFillPhone />
                </button> */}
                {/* <button className="card_info_btn">
                  <Md.MdEmail />
                </button>
                <button className="card_info_btn">
                  <Bi.BiGlobe />
                </button> */}
              </div>
            </div>
          </div>

          <div className="cmnt_wrapper">
            <div className="d-flex">
              <div className="customer_name">{props.name}</div>
              <p className="customer_mail">{props.email}</p>
            </div>
            <p className="user_connection">{props?.userConnection ?? "N/A"}</p>
            <p className="comp-des">{props.comment}</p>
          </div>
          <div className="row detail">
            <div className="col-one">
              <p id="review_info_">
                <Ai.AiFillCalendar /> <span>{props.date}</span>
              </p>
            </div>
            <div className="col-two">
              <p id="review_info_">
                <Ri.RiChatSmile3Fill /> <span>{props.reaction}</span>
              </p>
            </div>
            <div className="col-three">
              <p className="site_">
                Click here to collect your debt!
                <span className="anchor">
                  <a
                    className="anchor"
                    target="_blank"
                    href="https://www.moneyclaimcollections.co.uk/"
                  >
                    Click here.
                  </a>
                </span>
              </p>
            </div>
            <button className="review-card-btn" onClick={handleNavigation}>
              Know more
              <Fi.FiArrowUpRight />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardReview;
