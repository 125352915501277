import React from "react";
import { useNavigate } from "react-router-dom";
import "./Backbutton.css";
function BackButton() {
    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1);
    };

    return (
        <button className="back-navigate" onClick={goBack}>Go Back</button>
    );
}

export default BackButton;
