import React, { useContext, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Header from "../../Components/Header/Header";
import * as Bs from "react-icons/bs";
import "./Explore.css";
import BackButton from "../../Components/Backbutton/Backbutton";
import Footer from "../../Components/Footer/Footer";
import "../Home/Home.css";
import ReactPaginate from "react-paginate";
import ExploreCard from "../../Components/ExploreCard/ExploreCard";
import companyPlaceholder from "../../Assets/buildings.png";
import useCompany from "../../Hooks/useCompany";
import { MagnifyingGlass } from "react-loader-spinner";
import { AppContext } from "../../Context/AppContext";
import SkeltLoader from "../../Components/SkeltLoader/SkeltLoader";
import Aos from "aos";

function Explore() {
  Aos.init();
  const params = useParams();
  const navigate = useNavigate();
  const { reviews } = useContext(AppContext);
  const { companyLoading, getCompanyProfileByCategory } = useCompany();
  const [company, setCompany] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [businessCategory, setBusinessCategory] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(0);
  const [loadCount, setLoadCount] = useState(10);

  useEffect(() => {
    findCompany();
  }, [currentPage]);

  useEffect(() => {
    const category = JSON.parse(sessionStorage.getItem("selectedCategory"));
    setBusinessCategory(category);
  }, []);

  const findCompany = async () => {
    const id = params.id;
    if (!id) return;
    const payload = {
      businessCategoryId: id,
      pageno: currentPage,
    };

    await getCompanyProfileByCategory(payload, {
      onSuccess: (res) => {
        if (!Array.isArray(res.data.Response)) return;
        setCompany(res.data.Response);
        setCompanies(res.data);
        setItemsPerPage(res.data.items_per_page);
      },
      onFailed: (err) => {
        console.log("error", err);
      },
    });
  };
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const getCommentsCount = (id) => {
    const count = reviews.reduce((acc, review) => {
      if (review.businessProfile === id) {
        return acc + 1;
      }
      return acc;
    }, 0);
    return count;
  };

  return (
    <div className="container-fluid">
      <Header />
      <section className="explore-page">
        <div className="mains backgrd">
          <div className="container">
            <div className="bar">
              <div className="container">
                <div className="right-bar">
                  {/* <select className="right-loc mt-3">
                    explore
                    <option value="">Filter categories</option>
                    <option value="a">Bank</option>
                    <option value="ab">Clothings</option>
                    <option value="abc">Travel Service</option>
                  </select> */}
                </div>
              </div>
            </div>
            <div className="adj-button">
              <BackButton />
            </div>

            <h3>{businessCategory?.businessCategoryName ?? "N/A"}</h3>

            <div className="container">
              {/* */}
              <div className="row">
                {!companyLoading ? (
                  company &&
                  company.map((item, key) => {
                    const address = item.address[0];
                    return (
                      <div className="col-lg-6 col-sm-12 col-md-12" key={key} data-aos="fade-up" data-aos-delay="100">
                        <ExploreCard
                          companyImage={
                            item && item.profilePic != null
                              ? process.env.REACT_APP_AWS_IMAGE_URL +
                                "businessprofilepics/" +
                                item.profilePic
                              : companyPlaceholder
                          }
                          companyName={item?.businessName ?? "N/A"}
                          categoryName={
                            item?.businessCategory?.businessCategoryName ??
                            "N/A"
                          }
                          companyDescription={item?.description ?? "N/A"}
                          street={address?.line1 ?? "N/A"}
                          state={address?.locality ?? "N/A"}
                          country={address?.country ?? "N/A"}
                          reaction={getCommentsCount(item._id)}
                          companyId={item?._id}
                          selectedCompany={item}
                        />
                        {item?._id == undefined && (
                          <p>
                            Writing a review for this company is not available
                          </p>
                        )}
                      </div>
                    );
                  })
                ) : (
                  <>
                    <SkeltLoader count={10} />
                  </>
                )}
              </div>
              <div className="pagination">
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  onPageChange={handlePageChange}
                  pageCount={Math.ceil(companies.totalresult / itemsPerPage)}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={3}
                  containerClassName={"pagination"}
                  previousLinkClassName={"page-link"}
                  nextLinkClassName={"page-link"}
                  disabledClassName={"disabled"}
                  activeClassName={"paging__link--active"}
                />
              </div>
              <br />
            </div>
          </div>
        </div>
      </section>
      <div className="container-fluid">
        <Footer />
      </div>
    </div>
  );
}

export default Explore;
