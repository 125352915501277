import React, { useState } from "react";
import BaseClient from "../Helpers/BaseClient";
import { APIEndpoints } from "../Constants/APIEndpoints";

const useProtest = () => {
  const [complaintCategory, setComplaintCategory] = useState([]);
  const [protestLoading, setProtestLoading] = useState(false);

  const getProtestComplaintsCategory = async () => {
    try {
      setProtestLoading(true);

      await BaseClient.get(APIEndpoints.getAllComplaints, {
        onSuccess: (res) => {
          if (!Array.isArray(res.data)) return;
          setComplaintCategory(res.data);
        },
        onFailed: (err) => {
          console.log(err);
        },
      });
    } finally {
      setProtestLoading(false);
    }
  };

  const addNewProtest = async (payload, { onSuccess, onFailed }) => {
    try {
      setProtestLoading(true);

      await BaseClient.post(APIEndpoints.addNewReview, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setProtestLoading(false);
    }
  };

  const deleteReview = async (id, { onSuccess, onFailed }) => {
    try {
      setProtestLoading(true);
      await BaseClient.delete(APIEndpoints.removeReview + `/${id}`, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setProtestLoading(false);
    }
  };

  return {
    getProtestComplaintsCategory,
    complaintCategory,
    protestLoading,
    addNewProtest,
    deleteReview,
  };
};

export default useProtest;
