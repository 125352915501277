import React, { useState } from "react";
import BaseClient from "../Helpers/BaseClient";
import { APIEndpoints } from "../Constants/APIEndpoints";
import CustomErrors from "../Constants/Errors";

const useAuth = () => {
  const [authLoading, setAuthLoading] = useState(false);
  const [cookieData, setCookieData] = useState(null);
  const [pageLoading, setPageLoading] = useState(true);
  const [cookieError, setCookieError] = useState(null);

  const registerUser = async (payload, { onSuccess, onFailed }) => {
    try {
      setAuthLoading(true);
      await BaseClient.post(APIEndpoints.register, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setAuthLoading(false);
    }
  };

  const loginUser = async (payload, { onSuccess, onFailed }) => {
    try {
      setAuthLoading(true);
      await BaseClient.post(APIEndpoints.login, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setAuthLoading(false);
    }
  };

  const getCookieData = async () => {
    try {
      setPageLoading(true);
      await BaseClient.get(APIEndpoints.getCookieData, {
        withCredentials: true,
        onSuccess: (res) => {
          // setSessionError(CustomErrors.SESSION_ERROR);
          if (res?.data != null && res?.data != "") {
            setCookieData(res?.data);
          }
        },
        onFailed: (err) => {
          console.error("Session Error", err);
          setCookieError(CustomErrors.SESSION_ERROR);
        },
      });
    } finally {
      setPageLoading(false);
    }
  };

  const deleteCookieData = async ({ onLogoutSuccess }) => {
    try {
      setAuthLoading(true);
      await BaseClient.delete(APIEndpoints.deleteCookieData, {
        withCredentials: true,
        onSuccess: (res) => {
          setCookieData(null);
          onLogoutSuccess && onLogoutSuccess();
        },
        onFailed: (err) => {
          console.error("Session Logout Error", err);
        },
      });
    } finally {
      setAuthLoading(false);
    }
  };

  const forgotPassword = async (userId, { onSuccess, onFailed }) => {
    try {
      setAuthLoading(true);
      await BaseClient.post(
        APIEndpoints.forgotPassword,
        { userId },
        {
          onSuccess: onSuccess,
          onFailed: onFailed,
        }
      );
    } finally {
      setAuthLoading(false);
    }
  };

  const OtpVerification = async (payload, { onSuccess, onFailed }) => {
    try {
      setAuthLoading(true);
      await BaseClient.post(APIEndpoints.resetOtp, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setAuthLoading(false);
    }
  };

  const resetUserPassword = async (payload, { onSuccess, onFailed }) => {
    try {
      setAuthLoading(true);
      await BaseClient.post(APIEndpoints.resetUserPassword, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setAuthLoading(false);
    }
  };

  const resendOTP = async (payload, { onSuccess, onFailed }) => {
    try {
      setAuthLoading(true);

      await BaseClient.post(APIEndpoints.resendOtp, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setAuthLoading(false);
    }
  };
  const forgotUserData = async (payload, { onSuccess, onFailed }) => {
    try {
      setAuthLoading(true);

      await BaseClient.post(APIEndpoints.getUserEmailorPhone, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setAuthLoading(false);
    }
  };

  return {
    registerUser,
    forgotPassword,
    OtpVerification,
    resetUserPassword,
    forgotUserData,
    authLoading,
    loginUser,
    getCookieData,
    cookieData,
    setCookieData,
    pageLoading,
    cookieError,
    deleteCookieData,
    resendOTP,
    setCookieError,
  };
};

export default useAuth;
