import React, { useContext, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import Logo from "../../Assets/logo-home.png";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../Context/AppContext";
import { PulseLoader } from "react-spinners";
import useAuth from "../../Hooks/useAuth";
import { useEffect } from "react";
function Otpverification() {
  const { page } = useParams();
  const navigate = useNavigate();
  const [OTP, setOTP] = useState("");
  const { OtpVerification, authLoading, resendOTP } = useContext(AppContext);
  const [userEmail, setUserEmail] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    const mail = sessionStorage.getItem("email");
    setUserEmail(mail);
  }, []);

  function handleOTPChange(event) {
    let newValue = event.replace(/\D/g, "");
    newValue = newValue.slice(0, 6);
    setOTP(newValue);
  }
  const verificationOtp = async (e) => {
    e.preventDefault();
    const userId = sessionStorage.getItem("userId");
    const payload = {
      userId: userId,
      OTP: OTP,
    };
    await OtpVerification(payload, {
      onSuccess: (res) => {
        toast.success(res.message);
        navigate("/login");
        setTimeout(() => {
          if (page == "reset-password") {
            navigate("/resetpassword");
          } else if (page == "verify") {
            navigate("/login");
            sessionStorage.clear();
          }
        }, 1000);
      },
      onFailed: (err) => {
        toast.error(err.message);
      },
    });
  };

  const resendUserOTP = async () => {
    const userId = sessionStorage.getItem("userId");
    if (!userId) return;
    const payload = {
      userId,
    };
    await resendOTP(payload, {
      onSuccess: (res) => {
        toast.success("OTP Resended");
      },
      onFailed: (error) => {
        toast.error(error.message);
        switch (error.errorCode) {
          case "USER_NOT_FOUND":
            setError("USER_NOT_FOUND");
            break;
          default:
            setError("UNEXPECTED ERROR");
        }
      },
    });
  };

  return (
    <div>
      <Toaster position="top-center" />
      <div className="container-fluid">
        <div className="login-form">
          <div className="text-center">
            <img src={Logo} alt="" className="company-im" />
          </div>
          <h4 className="title text-center">OTP VERIFICATION</h4>
          <form onSubmit={verificationOtp}>
            <p className="para text-center" style={{ color: "#747474" }}>
              An otp has been sent to <br />
              <b className="email-id">{userEmail}</b>
            </p>
            <div className="form-group my-3">
              <label htmlFor="" className="form-label review-label">
                Enter OTP
              </label>
              <div className="review-group">
                <input
                  type="text"
                  className="form-control review-input"
                  maxLength={6}
                  onChange={(e) => handleOTPChange(e.target.value)}
                  value={OTP}
                  required
                />
              </div>
            </div>
            <p className="resend">
              Didn't get the OTP? <a onClick={resendUserOTP}>Resend</a>
            </p>
            <p className="float-left nav-go">
              <a href="/" className="navs">
                Go Home ?
              </a>
            </p>
            <div className="text-center mt-3">
              <a className="white-a" href="">
                <button type="submit" className="sub-bt" disabled={authLoading}>
                  {authLoading ? (
                    <PulseLoader color="#fff" size={10} />
                  ) : (
                    "Submit"
                  )}
                </button>
              </a>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Otpverification;
