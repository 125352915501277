import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Banner from "../../Assets/bannerhome.png";
import compayPlaceholder from "../../Assets/buildings.png";
import business from "../../Assets/business.jpg";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import CardReview from "../../Components/Review-card/CardReview";
import { AppContext } from "../../Context/AppContext";
import Utils from "../../Utils/Utils";
import SearchBar from "../../Components/SearchBar/SearchBar";
import ReactPaginate from "react-paginate";
import SkeltLoader from "../../Components/SkeltLoader/SkeltLoader";
import Aos from "aos";
import "aos/dist/aos.css";

function Home() {
  Aos.init();
  const {
    reviews,
    contentLoading,
    content,
    businessCategories,
    companies,
    reviewLoading,
  } = useContext(AppContext);

  const [currentPage, setCurrentPage] = useState(0);
  const navigate = useNavigate();

  const [homeData, setHomeData] = useState(null);
  useEffect(() => {
    const data = content != null && content.find((x) => x.pageName === "Home");
    if (data) {
      setHomeData(data);
    }
  }, [content]);

  const getCommentsCount = (id) => {
    const count = reviews.reduce((acc, review) => {
      if (review.businessProfile === id) {
        return acc + 1;
      }
      return acc;
    }, 0);

    return count;
  };

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };
  const navigateToExplore = (value) => {
    sessionStorage.setItem("selectedCategory", JSON.stringify(value));
    navigate(`explore/${value._id}`);
  };
  const itemsPerPage = 10;
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  return (
    <div className="container-fluid">
      <Header />
      <section className="review-area">
        <div className="container-fluid home-view">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-4" id="banner-img-cont">
                <div className="home-lot">
                  <img src={Banner} alt="" className="banner-home" />
                </div>
              </div>
              <div className="col-lg-8 col-md-6 col-sm-12">
                <div className="banner-content">
                  <div className="vertical-text">
                    <div className="tag">YOUR</div>
                    <div className="tag">SHARE</div>
                  </div>
                  <div className="banner-words">
                    <h6 className="banner-head">
                      <span>
                        Negative or Positive <br />
                        Experience with <br />
                      </span>
                      Trusted Payer
                    </h6>
                    <SearchBar data={companies} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="under-red"></div>
        </div>
        {/* Banner Area End Here */}
        <section className="home-content">
          <div className="container grid-list">
            <h3 data-aos="zoom-in">Explore Category</h3>
            <div className="row">
              {businessCategories.map((category, index) => {
                return (
                  <div
                    className="col-lg-3 col-md-4 col-sm-4"
                    key={index}
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <a onClick={() => navigateToExplore(category)}>
                      <div className="data-box">
                        <div className="flex-container">
                          <span className="tag-icon">
                            <img
                              src={
                                category.image != null
                                  ? process.env.REACT_APP_AWS_IMAGE_URL +
                                    "categoryimages/" +
                                    category.image
                                  : business
                              }
                              alt=""
                              className="business-pic"
                            />
                          </span>
                          <span className="text">
                            <p>{category.businessCategoryName}</p>
                          </span>
                        </div>
                      </div>
                    </a>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="container">
            <div className="d-flex position-relative">
              <h3>Recently Listed</h3>
              {/* <span className="right">
                <div className="sort">
                  <select className="select">
                    <option value="">Most Relevant</option>
                    <option value="a">Most Recent</option>
                    <option value="ab">Rating</option>
                    <option value="abc">Review</option>
                  </select>
                </div>
              </span> */}
            </div>
            <br />
            <div className="row math-row">
              {!reviewLoading ? (
                reviews != null &&
                reviews.slice(startIndex, endIndex).map((review, index) => {
                  const user = review.user;
                  const isAnonymous = review.anonymous === true;

                  if (!review?.disable) {
                    return (
                      <div className="col-lg-6 col-md-12 col-sm-12" key={index}>
                        {!review.disable && (
                          <CardReview
                            companyImage={compayPlaceholder}
                            companyName={review?.businessName ?? "N/A"}
                            complaintCategory={
                              review?.complaintCategory
                                ?.complaintCategoryName ?? "N/A"
                            }
                            comment={review?.protest ?? "N/A"}
                            // location="location"
                            reaction={
                              review != null
                                ? getCommentsCount(review.businessProfile)
                                : "N/A"
                            }
                            name={
                              isAnonymous
                                ? "Anonymous"
                                : user?.fullName ?? "N/A"
                            }
                            email={isAnonymous ? "" : user?.email ?? "N/A"}
                            userConnection={
                              isAnonymous
                                ? ""
                                : review?.companyConnection ?? "N/A"
                            }
                            date={
                              review.createdAt != null
                                ? Utils.formatDate(review.createdAt)
                                : "N/A"
                            }
                            selectedCompany={review}
                            navigateLink={`overview/${review.businessProfile}`}
                            pdffile={review?.pdffile}
                          />
                        )}
                      </div>
                    );
                  }
                })
              ) : (
                <>
                  <SkeltLoader count={4} />{" "}
                </>
              )}

              <div className="col-md-3 order-md-last order-first"></div>
              <div className="pagination">
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  onPageChange={handlePageChange}
                  pageCount={Math.ceil(reviews.length / itemsPerPage)}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={3}
                  containerClassName={"pagination"}
                  previousLinkClassName={"page-link"}
                  nextLinkClassName={"page-link"}
                  disabledClassName={"disabled"}
                  activeClassName={"paging__link--active"}
                />
              </div>
            </div>
            <br />
          </div>
          <div className="container-fluid last-box">
            <h2 className="foot-head text-center">
              {homeData?.contents?.topHeading ?? "Not Available"}
            </h2>
            <div className="container py-5">
              <div className="row">
                <div className="col-md-4">
                  <div className="foot-content">
                    <h6>{homeData?.contents?.heading1 ?? "Not Available"}</h6>
                    <p>{homeData?.contents?.para1 ?? "Not Available"}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="foot-content">
                    <h6>{homeData?.contents?.heading2 ?? "Not Available"}</h6>
                    <p>{homeData?.contents?.para2 ?? "Not Available"}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="foot-content">
                    <h6>{homeData?.contents?.heading3 ?? "Not Available"}</h6>
                    <p>{homeData?.contents?.para3 ?? "Not Available"}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Home-Content End Here */}
      </section>
      <Footer />
    </div>
  );
}
export default Home;
