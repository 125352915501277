import React from "react";
import "./Loader.css";
import Lottie from "react-lottie";
import loadingData from "../../Assets/Lottie/97018-window10-style-login-loader.json";

function Loader() {
    const lottieOption = {
        loop: true,
        autoplay: true,
        animationData: loadingData,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      };
  return (
    <>
      <div className="loader">
        <div className="loader-lottie">
          <Lottie options={lottieOption} width={150} height={150}></Lottie>
        </div>
      </div>
    </>
  );
}

export default Loader;
