import React from "react";
import Error from "../Pages/Error/Error";
import Home from "../Pages/Home/Home";
import Login from "../Pages/Login/Login";
import Signup from "../Pages/Signup/Signup";
import Register from "../Pages/Register/Register";
import Contact from "../Pages/Contact/Contact";
import About from "../Pages/About/About";
import Explore from "../Pages/Explore/Explore";
import Singlepage from "../Pages/Singlepage/Singlepage";
import ResetPassword from "../Pages/ResetPassword/ResetPassword";
import Changepassword from "../Pages/ResetPassword/Changepassword";
import Otpverification from "../Pages/ResetPassword/Otpverification";
import Profile from "../Pages/Profile/Profile";
import Password from "../Pages/Profile/Password";
import ShowProfile from "../Pages/ResetPassword/ShowProfile";
import OTPChange from "../Pages/ResetPassword/OTPChangs";
import Banned from "../Components/Banned/Banned";

const AppRoutes = [
  {
    isProtected: false,
    isProtectedAuth: true,
    path: "/login",
    element: <Login />,
  },
  {
    isProtected: false,
    isProtectedAuth: true,
    path: "/signup",
    element: <Signup />,
  },
  { isProtected: false, isProtectedAuth: false, path: "/", element: <Home /> },
  {
    isProtected: true,
    path: "/register/:id?/:companyname?",
    element: <Register />,
  },
  {
    isProtected: false,
    path: "/contact",
    element: <Contact />,
  },
  {
    isProtected: false,
    path: "/about",
    element: <About />,
  },
  {
    isProtected: false,
    path: "/explore/:id",
    element: <Explore />,
  },
  {
    isProtected: false,
    path: "/overview/:id",
    element: <Singlepage />,
  },
  {
    isProtected: false,
    isProtectedAuth: false,
    path: "/reset",
    element: <ResetPassword />,
  },
  {
    isProtected: false,
    isProtectedAuth: false,
    path: "/resetpassword",
    element: <Changepassword />,
  },
  {
    isProtected: false,
    isProtectedAuth: true,
    path: "/otpverification/:page",
    element: <Otpverification />,
  },
  {
    isProtected: false,
    isProtectedAuth: true,
    path: "/changeuserData",
    element: <OTPChange />,
  },
  {
    isProtected: true,
    isProtectedAuth: true,
    path: "/profile",
    element: <Profile />,
  },
  {
    isProtected: false,
    isProtectedAuth: false,
    path: "/password",
    element: <Password />,
  },
  {
    isProtected: false,
    isProtectedAuth: false,
    path: "/show-profile",
    element: <ShowProfile />,
  },
  {
    isProtected: false,
    path: "/error",
    element: <Error />,
  },
  {
    isProtected: false,
    path: "/banned-user",
    element: <Banned />,
  },
];

export default AppRoutes;
