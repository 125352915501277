import React, { useContext, useEffect, useState } from "react";
import dataCollection from "./FAQ";
import "./Accordion.css";
import { AppContext } from "../../Context/AppContext";

function Accordion() {
  const { content } = useContext(AppContext);
  const [accordion, setAccordion] = useState(0);
  const [data, setData] = useState(null);

  useEffect(() => {
    const data =
      content != null && content.find((x) => x.pageName === "Contact");
    if (data) {
      setData(data?.contents?.questions);
    }
  }, [content]);

  const toggleAccordion = (index) => {
    setAccordion(index);
  };

  return (
    <>
      <div className="container">
        {data != null &&
          data.map((item, index) => {
            return (
              <div key={index} onClick={() => toggleAccordion(index)}>
                <div className="accordion_faq_qus">
                  <h3 className={accordion === index ? "active" : ""}>
                    {item.question}
                  </h3>
                  <div>
                    {accordion === index ? (
                      <span className="verticle">-</span>
                    ) : (
                      <span className="verticle">+</span>
                    )}
                  </div>
                </div>

                <div>
                  <p
                    className={
                      accordion === index ? "answer active" : "answer inactive"
                    }
                  >
                    {item.answer}
                  </p>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
}

export default Accordion;
