import React, { useContext, useEffect, useState } from "react";
import Header from "../../Components/Header/Header";
import { AppContext } from "../../Context/AppContext";
import { useParams, useNavigate } from "react-router-dom";
import "./Singlepage.css";
import * as Ai from "react-icons/ai";
import * as Tfi from "react-icons/tfi";
import * as Fi from "react-icons/fi";
import * as Md from "react-icons/md";
import * as Bs from "react-icons/bs";
import Footer from "../../Components/Footer/Footer";
import ReactReadMoreReadLess from "react-read-more-read-less";
import BackButton from "../../Components/Backbutton/Backbutton";
import CardReview from "../../Components/Review-card/CardReview";
import compayPlaceholder from "../../Assets/buildings.png";
import Utils from "../../Utils/Utils";
import { HashLoader } from "react-spinners";
import useCompany from "../../Hooks/useCompany";
import comp from "../../Assets/business.jpg";
import personPlaceholder from "../../Assets/man.png";
import SkeltLoader from "../../Components/SkeltLoader/SkeltLoader";
import Skeleton from "react-loading-skeleton";

function Singlepage() {
  const params = useParams();
  const navigate = useNavigate();
  const { reviews, usersList } = useContext(AppContext);
  const { companyLoading, getCompanyProfileById } = useCompany();
  const [selectedCompany, setSelectedCompany] = useState([]);
  const [reviewData, setReviewData] = useState([]);
  const [category, setCategory] = useState([]);

  useEffect(() => {
    getReview();
  }, [reviews]);

  useEffect(() => {
    getCompany();
    const categoryData = JSON.parse(sessionStorage.getItem("selectedCategory"));
    setCategory(categoryData);
  }, []);

  const getReview = () => {
    const id = params.id;
    if (!id) return;
    const getReviewData = reviews.filter((x) => x.businessProfile == id);
    setReviewData(getReviewData);
  };

  const getCompany = async () => {
    const id = params.id;
    if (!id) return;

    const payload = {
      businessProfileId: id,
    };

    await getCompanyProfileById(payload, {
      onSuccess: (res) => {
        if (!res.data) return;
        setSelectedCompany(res.data);
      },
      onFailed: (err) => {
        console.log(err);
      },
    });
  };
  return (
    <div>
      <Header />
      <div className="main">
        <div className="container-fluid back-grd">
          <div className="container adjust-cont">
            <div className="nav-back">
              <BackButton />
            </div>
            <div className="flex card review-card">
              <div className="box-for-details">
                {!companyLoading ? (
                  <>
                    <div className="second">
                      <div className="img-place">
                        <img src={comp} alt="" className="rounded-img" />
                      </div>
                    </div>
                    <div className="first">
                      <p className="comp-names">
                        {selectedCompany?.businessName}
                      </p>

                      {/* <div className="customer-review margin">
                          {selectedCompany?.businessCategory?.businessCategoryName ?? "N/A"}
                        </div> */}
                      <div className="customer-review">
                        <Md.MdOutlineLocationOn />
                        <span>
                          {selectedCompany &&
                            selectedCompany.address &&
                            selectedCompany.address.map((address) => {
                              return (
                                <>
                                  <span>{address?.line2 ?? "N/A"},</span>
                                  <span>{address?.locality ?? "N/A"},</span>
                                  <span>{address?.postalCode ?? "N/A"}.</span>
                                </>
                              );
                            })}
                        </span>
                      </div>
                      {/* <div className="customer-review">
                    <Md.MdOutlineDescription />
                    <span>Test description</span>
                  </div> */}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="second">
                      <div className="img-place">
                        <Skeleton circle width={100} height={100} />
                      </div>
                    </div>
                    <div className="first">
                      <p className="comp-names">
                        <Skeleton width={200} />
                      </p>
                      <div className="customer-review">
                        <span>
                          <Skeleton width={250} />
                        </span>
                      </div>
                    </div>
                  </>
                )}
                <div className="box-for-btn">
                  <button
                    className="complaint"
                    onClick={() =>
                      navigate(
                        `/register/${params.id}/${selectedCompany.businessName}`
                      )
                    }
                    disabled={companyLoading}
                  >
                    <Ai.AiOutlineEdit /> Write a Review
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid back-gr">
          <div className="section single_review_section">
            <div className="container">
              <h3 className="mt-3 mb-3">
                Reviews
                {/* <span className="right">
                <div className="sort">
                  <select>
                    <option value="">Sortby</option>
                    <option value="a">Most Recent</option>
                    <option value="ab">Rating</option>
                    <option value="abc">Review</option>
                  </select>
                </div>
              </span> */}
              </h3>
              <div className="row">
                {reviewData != null && reviewData.length != 0 ? (
                  reviewData.map((review, index) => {
                    const user = review.user;
                    const isAnonymous = review.anonymous === true;
                    return (
                      <div className="col-md-6 " key={index}>
                        <div className="review-card single-review">
                          <div className="all-boxes">
                            <div className="flex-x">
                              <img
                                src={personPlaceholder}
                                alt=""
                                className="roun-img"
                              />
                            </div>
                            <div className="flex-y">
                              <p className="head-co">
                                {isAnonymous
                                  ? "Anonymous"
                                  : user?.fullName ?? "N/A"}
                              </p>
                              <p className="cats">
                                {isAnonymous
                                  ? ""
                                  : review?.companyConnection ?? "N/A"}
                              </p>
                            </div>
                          </div>
                          <hr />
                          <div>
                            <p className="rev-cont">
                              <ReactReadMoreReadLess
                                charLimit={320}
                                readMoreText={"Read more"}
                                readLessText={"Read less"}
                              >
                                {review?.protest ?? "N/A"}
                              </ReactReadMoreReadLess>
                            </p>
                          </div>

                          <div className="row">
                            <div className="button_grp_ d-flex">
                              <div className="customer-review d-flex">
                                <p>
                                  {review.createdAt != null
                                    ? Utils.formatDate(review.createdAt)
                                    : "N/A"}
                                </p>
                              </div>
                              {/* <div className="customer-review const share">
                                <i>
                                  <Ai.AiOutlineShareAlt />
                                </i>
                                <span>Share</span>
                              </div>
                              <div className="customer-review ps-4 helpful">
                                <i>
                                  <Ai.AiFillLike />
                                </i>
                                <span>Helpful</span>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <>
                    <section className="no-data">
                      <h1>NO REVIEWS ARE REGISTERED</h1>
                    </section>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid stand">
        {/* <div className="container">
          <p className="single-head">
            <u>About Company</u>
          </p>
          <p className="head-para">{selectedCompany?.description ?? "N/A"}</p>
          <hr />
        </div> */}
        {/* <div className="container stand">
          <div className="row">
            <div className="col-md-8">
              <h3 className="mt-3 mb-3">
                Complaints
                <span className="right">
                  <div className="sort">
                    <select>
                      <option value="">Sortby</option>
                      <option value="a">Most Recent</option>
                      <option value="ab">Rating</option>
                      <option value="abc">Review</option>
                    </select>
                  </div>
                </span>
              </h3>
              {reviewData != null &&
                reviewData.map((review, index) => {
                  const user = review.user;
                  return (
                    <CardReview
                      companyImage={compayPlaceholder}
                      companyName={review?.businessName ?? "N/A"}
                      comment={review?.review ?? "N/A"}
                      // location="location"

                      name={user?.fullName ?? "N/A"}
                      email={user?.email ?? "N/A"}
                      date={
                        review.createdAt != null
                          ? Utils.formatDate(review.createdAt)
                          : "N/A"
                      }
                      businessCategory={
                        review?.businessCategory?.businessCategoryName ?? "N/A"
                      }
                      selectedCompany={review}
                      navigateLink={`overview/${review._id}`}
                    />
                  );
                })}
            </div>
          </div>
        </div> */}
        <div>
          <Footer />
        </div>
      </div>
    </div>
  );
}
export default Singlepage;
