export const APIEndpoints = {
  register: "auth/registeruser",
  login: "auth/userlogin",
  forgotPassword: "auth/forgotpassword",
  resetOtp: "auth/verifyotp",
  resetUserPassword: "auth/resetpassword",
  resendOtp: "auth/resendotp",
  editPassword: "auth/changepassword",

  getCookieData: "cookies/getusertoken",
  deleteCookieData: "cookies/deleteusertoken",

  getAllUsers: "user/getallusers",
  getSingleUserById: "user/getuserbyid",

  getAllReviews: "review/getallreviews",
  addNewReview: "review/addoreditreview",
  removeReview: "review/deletereview",

  getAllBusinessCategories: "businesscategory/getallbusinesscategories",

  getAllBusinessProfiles: "business/getallbusinesprofiles",
  Contactus: "contactus/addcontactus",
  getBusinessProfileByCategory: "business/company-list-based-on-category",
  getBusinessProfileById: "business/getbusinesprofilebyid",
  searchBusinessByName: "business/searchcompaniesbyname",

  getAllComplaints: "complaintcategory/getallcomplaintcategories",
  updateUserDetails: "user/updateuser",
  fetchDataOfUser: "review/getallreviewsofuser",
  getUserEmailorPhone: "auth/getuserbyemailorphone",

  getAllPageData: "user/pages/get-page-details",
};
