import React, { Fragment } from "react";
import { Link } from "react-router-dom";

function NavLinks({ routes, isUrl, click }) {
  if (!routes || !isUrl || click == null) return;
  return (
    <Fragment>
      {click ? (
        <nav>
          <ul className={click ? "nav_items active" : "nav_items"}>
            {routes &&
              routes.map((route, index) => {
                if (route.published) {
                  return (
                    <li key={index}>
                      <Link
                        exact="true"
                        to={route.route}
                        className={
                          "nav_links" +
                          (isUrl === route.route ? " selected" : "")
                        }
                      >
                        {route.pageName}
                      </Link>
                    </li>
                  );
                }
              })}
            <li>
              <Link
                exact="true"
                to="/register"
                className={
                  "nav_links2" + (isUrl === "/register" ? " selected" : "")
                }
              >
                Write a Review
              </Link>
            </li>
          </ul>
        </nav>
      ) : (
        <nav>
          <ul className={click ? "nav_items2 active" : "nav_items2"}>
            {routes.map((route, index) => {
              if (route.published) {
                return (
                  <li key={index}>
                    <Link
                      exact="true"
                      to={route.route}
                      className={
                        "nav_links2" +
                        (isUrl === route.route ? " selected" : "")
                      }
                    >
                      {route.pageName}
                    </Link>
                  </li>
                );
              }
              return null;
            })}
            <li>
              <Link
                exact="true"
                to="/register"
                className={
                  "nav_links2" + (isUrl === "/register" ? " selected" : "")
                }
              >
                Write a Review
              </Link>
            </li>
          </ul>
        </nav>
      )}
    </Fragment>
  );
}

export default NavLinks;
