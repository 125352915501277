import React from "react";
import toast, { Toaster } from "react-hot-toast";
import Logo from "../../Assets/logo-home.png";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AppContext } from "../../Context/AppContext";
import { useState } from "react";

function ShowProfile() {
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const { forgotPassword } = useContext(AppContext);
  const userDataJSON = sessionStorage.getItem("userData");

  const userData = userDataJSON ? JSON.parse(userDataJSON).data : null;
  // console.log(userData);
  const confirmMail = async (e) => {
    e.preventDefault();
    const userId = userData?._id;
    await forgotPassword(userId, {
      onSuccess: (res) => {
        toast.success("OTP has been successfully sent to your email!");
        window.sessionStorage.setItem("userId", userData?._id);
        setTimeout(() => {
          navigate("/changeuserData");
        }, 1500);
      },
      onFailed: (error) => {
        toast.error(error.message);
        switch (error.errorCode) {
          case "USER_NOT_FOUND":
            setError("USER_NOT_FOUND");
            break;
          default:
            setError("UNEXPECTED ERROR");
        }
        console.log(error.message);
      },
    });
  };
  return (
    <div>
      <div className="container-fluid">
        <Toaster position="top-center" />
        <div className="col-lg-5 col-md-6 col-sm-12 mx-auto is-this-pro">
          <div className="card p-3">
            <div className="text-center">
              <img src={Logo} alt="" className="company-im" />
            </div>
            <h4 className="title text-center">Trusted Payer</h4>
            <div className="profile-card mx-auto">
              <h3 className="text-center ques-text">Is this Your Profile?</h3>
              <div class="box1 boxed-res">
                <div class="content">
                  <div class="image">
                    <img
                      src={
                        userData.profilepic != null
                          ? process.env.REACT_APP_AWS_IMAGE_URL +
                            `userprofilepics/` +
                            userData.profilepic
                          : ""
                      }
                      alt="Profile Image"
                    />
                  </div>

                  <div class="texted">
                    <p class="name">{userData.fullName ?? "N/A"}</p>
                    <p class="email-pro">{userData?.email ?? "N/A"}</p>
                    <p class="job_title">{userData.phone ?? "N/A"}</p>
                  </div>
                  <div className="buttons text-center">
                    <button className="confirm-btn mb-2" onClick={confirmMail}>
                      Confirm
                    </button>
                    <div className="or_ mt-3">
                      <span>OR</span>
                    </div>
                    <br />
                    <a className="close-pr" href="/signup">
                      <p className="warn-msg text-center">
                        This is not my Profile!
                      </p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* <div class="">
              <div class="">
                <h4 className="text-center warn-msg">No Profile Found</h4>
                <div className="text-center">
                  <button className="create-acc ">Create New Account?</button>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShowProfile;
