import React from "react";
import { useNavigate } from "react-router-dom";
import * as Ai from "react-icons/ai";
import * as Bi from "react-icons/bi";
import * as Fi from "react-icons/fi";
import * as Md from "react-icons/md";
import * as Ri from "react-icons/ri";
import * as Bs from "react-icons/bs";
import "./ExploreCard.css";
import toast from "react-hot-toast";

function ExploreCard(props) {
  const navigate = useNavigate();
  const handleNavigate = () => {
    console.log(props.companyId, "companyifd");
    if (props.companyId) {
      navigate(`/overview/${props.companyId}`);
      if (props.selectedCompany) {
        sessionStorage.setItem(
          "selectedCompany",
          JSON.stringify(props.selectedCompany)
        );
      }
    }
  };
  return (
    <a className="card_" onClick={handleNavigate}>
      <div className="explore-card">
        <div className="flex-1">
          <div className="boxe">
            <img src={props.companyImage} alt="" className="comp-identifier" />
          </div>
          <div className="boxed">
            <p className="comp-n">{props?.companyName ?? "N/A"}</p>
            {props.companyId == undefined ? (
              <p
                className="comp-a"
                style={{ color: "red", fontSize: "10px", padding: "2px" }}
              >
                Writing a review for this company is not available
              </p>
            ) : (
              <p className="comp-a">{props?.categoryName ?? "N/A"}</p>
            )}

            <p className="comp-address">
              <Md.MdLocationPin />
              <span>{props?.street ?? "N/A"}</span>,
              <span>{props?.state ?? "N/A"}</span>,
              <span>{props?.country ?? "N/A"}</span>.
            </p>
          </div>
        </div>
        <div className="row">
          <p className="comp-d">{props?.companyDescription ?? "N/A"}</p>
        </div>
        <div className="row details_">
          <div className="col-auto">
            <p id="review_info_">
              <Ri.RiChatSmile3Fill /> <span>{props?.reaction ?? "N/A"}</span>
            </p>
          </div>
        </div>
      </div>
    </a>
  );
}

export default ExploreCard;
