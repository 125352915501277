import React, { useState } from "react";
import BaseClient from "../Helpers/BaseClient";
import { APIEndpoints } from "../Constants/APIEndpoints";

const useCompany = () => {
  const [companies, setCompanies] = useState([]);
  const [companyLoading, setCompanyLoading] = useState(false);

  const getAllCompaniesList = async () => {
    try {
      setCompanyLoading(true);
      await BaseClient.get(APIEndpoints.getAllBusinessProfiles, {
        onSuccess: (res) => {
          if (!Array.isArray(res.data)) return;
          setCompanies(res.data);
        },
        onFailed: (err) => {
          console.log("ERROR WHILE FETCHING COMPANIES", err.message);
        },
      });
    } finally {
      setCompanyLoading(false);
    }
  };

  const getCompanyProfileByCategory = async (
    payload,
    { onSuccess, onFailed }
  ) => {
    try {
      setCompanyLoading(true);

      await BaseClient.post(
        APIEndpoints.getBusinessProfileByCategory,
        payload,
        {
          onSuccess: onSuccess,
          onFailed: onFailed,
        }
      );
    } finally {
      setCompanyLoading(false);
    }
  };

  const getCompanyProfileById = async (payload, { onSuccess, onFailed }) => {
    try {
      setCompanyLoading(true);

      await BaseClient.post(APIEndpoints.getBusinessProfileById, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setCompanyLoading(false);
    }
  };

  const searchBusinessName = async (payload, { onSuccess, onFailed }) => {
    await BaseClient.post(APIEndpoints.searchBusinessByName, payload, {
      onSuccess: onSuccess,
      onFailed: onFailed,
    });
  };

  return {
    getAllCompaniesList,
    companies,
    companyLoading,
    getCompanyProfileByCategory,
    getCompanyProfileById,
    searchBusinessName,
  };
};
export default useCompany;
