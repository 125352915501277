import React, { useState, useContext } from "react";
import Logo from "../../Assets/logo-home.png";
import "./Reset.css";
import * as Ai from "react-icons/ai";
import toast, { Toaster } from "react-hot-toast";
import { AppContext } from "../../Context/AppContext";
import { useNavigate } from "react-router-dom";
import { PulseLoader } from "react-spinners";

function ResetPassword() {
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const { forgotUserData, authLoading } = useContext(AppContext);

  const handleProfile = async (e) => {
    e.preventDefault();

    if (!(email || phone)) {
      setError(true);
      return;
    }

    let payload;
    if (phone) {
      payload = { phone };
    } else {
      payload = { email };
    }

    await forgotUserData(payload, {
      onSuccess: async (res) => {
        if (!res.data) return;
        const userDataJSON = JSON.stringify(res);
        sessionStorage.setItem("userData", userDataJSON);
        toast.success("Submitted Successfully!");
        navigate("/show-profile");
        setError(false);
      },
      onFailed: (err) => {
        toast.error(err.message);
      },
    });
  };

  return (
    <div>
      <div className="container-fluid">
        <Toaster position="top-center" />
        <div className="login-form">
          <div className="text-center">
            <img src={Logo} alt="" className="company-im" />
          </div>
          <h4 className="title text-center">Confirm Email</h4>
          <form onSubmit={handleProfile}>
            <p className="para text-center">
              Enter Registered Email or Phone number{" "}
            </p>
            <div className="form-group my-3">
              <label htmlFor="" className="form-label review-label">
                Email/Phone Number
              </label>
              <div className="review-group">
                <input
                  type="text"
                  className="form-control review-input"
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (inputValue.includes("@")) {
                      setEmail(inputValue);
                      setPhone("");
                    } else {
                      setPhone(inputValue);
                      setEmail("");
                    }
                  }}
                  name="email"
                  value={email || phone}
                  required
                />

                <i className="inp_ico">
                  <Ai.AiOutlineMail />
                </i>
              </div>
            </div>
            <p className="float-left nav-go">
              <a href="/" className="navs">
                Go Home ?
              </a>
            </p>
            <div className="text-center">
              <button type="submit" className="sub-bt">
                <a className="white-a">
                  {authLoading ? (
                    <PulseLoader color="#fff" size={10} />
                  ) : (
                    "Submit"
                  )}
                </a>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
