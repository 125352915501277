import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Login.css";
import logoImage from "../../Assets/logo-home.png";
import * as Ai from "react-icons/ai";
import useAuth from "../../Hooks/useAuth";
import useCookies from "../../Hooks/useCookies";
import toast, { Toaster } from "react-hot-toast";
import { SyncLoader } from "react-spinners";
import "../Signup/Signup.css";
import { PulseLoader } from "react-spinners";
import { Toast } from "react-bootstrap";

function Login() {
  const { loginUser, authLoading } = useAuth();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [userIsBanned, setUserIsBanned] = useState(false);

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const loginSubmit = async (e) => {
    e.preventDefault();

    if (email.length == 0 || password.length == 0) {
      setError(true);
      return;
    }

    const payload = {
      email,
      password,
    };

    await loginUser(payload, {
      onSuccess: async (res) => {
        toast.success("Welcome to Trusted Payer!");
        sessionStorage.setItem("userId", res.data.userId);
        console.log(res);
        setTimeout(() => {
          window.location.href = "/";
        }, 1800);
      },
      onFailed: (err) => {
        if (err.errorCode === "UBB") {
          // toast("Sorry You are Banned!", {
          //   icon: "🚩",
          //   style: {
          //     borderRadius: "10px",
          //     background: "#333",
          //     color: "#fff",
          //   },
          // });
          setUserIsBanned(true);
        } else {
          toast.error(err.message);
        }
      },
    });
  };

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <div className="login-container">
        <div className="login-form left">
          <img src={logoImage} alt="" className="logo" />

          <h2 className="title text-center">Login</h2>
          <form onSubmit={(e) => loginSubmit(e)}>
            <p className="para text-center">Enter your login credentials</p>
            <div className="form-group my-3">
              <label htmlFor="" className="form-label review-label">
                Email
              </label>
              <div className="review-group">
                <input
                  type="email"
                  className="form-control review-input"
                  name="email"
                  placeholder="Email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <i className="inp_ico">
                  <Ai.AiOutlineMail />
                </i>
              </div>
              {error && email.length == 0 && (
                <span className="err_msg">Email is required!</span>
              )}
            </div>
            <div className="form-group my-3">
              <label htmlFor="" className="form-label review-label">
                Password
              </label>
              <div className="review-group">
                <input
                  className="form-control review-input"
                  type={passwordType}
                  placeholder="Enter Password"
                  name="password"
                  autoComplete="off"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <i className="inp_ico" id="pass-eye" onClick={togglePassword}>
                  {passwordType === "password" ? (
                    <Ai.AiOutlineEye />
                  ) : (
                    <Ai.AiOutlineEyeInvisible />
                  )}
                </i>
              </div>
              {error && password.length == 0 && (
                <span className="err_msg">Password is required!</span>
              )}
            </div>
            <div>
              <p className="paragraph">
                <a href="/reset">Forgot Username/password ?</a>
                <span>
                  <a href="/" className="navs float-right">
                    Go Home
                  </a>
                </span>
              </p>
            </div>
            <div className="text-center">
              <button type="submit" className="sub-bt" disabled={authLoading}>
                {authLoading ? <PulseLoader color="#fff" size={10} /> : "login"}
              </button>
            </div>
          </form>
          {userIsBanned && (
            <div className="container">
              <div class="alert alert-danger " role="alert">
                <button
                  className="close_btn"
                  onClick={() => setUserIsBanned(false)}
                >
                  <Ai.AiOutlineCloseCircle />
                </button>
                We regret to inform you that your account has been Banned for
                some reason. As a result, you will not be able to access your
                account or any of its associated features. Please contact
                support for further assistance!
              </div>
            </div>
          )}
          <p className="signup text-center">
            Don't have an account? <a href="signup">Signup</a>
          </p>
        </div>
        <div className="error_info_wrapper"></div>
      </div>
    </>
  );
}

export default Login;
