import React, { useState, useCallback, useContext, useRef } from "react";
import { useNavigate } from "react-router";
import * as Bs from "react-icons/bs";
import { AppContext } from "../../Context/AppContext";

// to avoid recreating it on each render
const debounce = (func, delay) => {
  let timeoutId;
  return function (...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(null, args);
    }, delay);
  };
};

function SearchBar() {
  const navigate = useNavigate();
  const buttonRef = useRef();
  const [filterData, setFilterData] = useState([]);
  const { searchBusinessName } = useContext(AppContext);
  const [value, setValue] = useState([]);

  const debouncedSearch = useCallback(
    debounce(async (value) => {
      const searchTerm = value;

      const payload = {
        searchTerm,
      };

      try {
        await searchBusinessName(payload, {
          onSuccess: (res) => {
            if (!Array.isArray(res.data)) return;
            setFilterData(res.data);
          },
          onFailed: (err) => {
            console.log("Error on Register", err.message);
          },
        });
      } catch (err) {
        console.log(err);
      }

      if (value == "") {
        setFilterData([]);
      }
    }, 100),
    []
  );

  function handleCompanyFilter(e) {
    setValue(e);
    setFilterData([]);
  }

  const handleFilter = async (value) => {
    setValue(value);
    debouncedSearch(value);
  };

  // const handleFilter = (value) => {
  //   const searchData = value;
  //   const newFilter = data.filter((item) => {
  //     return item.businessName
  //       .toString()
  //       .toLowerCase()
  //       .includes(searchData.toString().toLowerCase());
  //   });
  //   if (searchData === "") {
  //     setFilterData([]);
  //   } else {
  //     setFilterData(newFilter);
  //   }
  // };

  const handleNavigation = (company) => {
    navigate(`/overview/${company?._id}`);
  };

  return (
    <div className="search-box">
      <form className="form-search">
        <button className="search-icon"></button>
        <input
          className="search-input"
          placeholder="Search by company"
          onChange={(e) => handleFilter(e.target.value)}
        />
      </form>
      {filterData.length > 0 && (
        <div className="search-results">
          {filterData.map((company, index) => (
            <a
              href=""
              className="searched-data"
              onClick={() => handleNavigation(company)}
              key={index}
            >
              <p>{company?.businessName ?? "N/A"}</p>
            </a>
          ))}
        </div>
      )}
    </div>
  );
}

export default SearchBar;
