import React, { useContext } from "react";
import { Navigate, Route, redirect, useLocation } from "react-router-dom";
import useCookies from "../Hooks/useCookies";
import { AppContext } from "../Context/AppContext";

export const ProtectRoute = ({ children }) => {
  const { cookieData, routes } = useContext(AppContext);

  if (cookieData == null) {
    return <Navigate to="/login" />;
  }
  return children;
};

export const ProtectAuthRoute = ({ children }) => {
  const { cookieData, routes } = useContext(AppContext);

  return !cookieData ? children : <Navigate to="/" />;
};
