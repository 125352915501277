import React from "react";
import "./Banned.css";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AppContext } from "../../Context/AppContext";

function Banned() {
  const { deleteCookieData } = useContext(AppContext);

  const handleLogout = async () => {
    await deleteCookieData({
      onLogoutSuccess: () => {
        setTimeout(() => {
          window.location.href = "/";
        }, 1000);
      },
    });
  };
  return (
    <div>
      <div className="banned_data">
        <div className="container">
          <h1 className="text-center title_">Sorry You are Banned!</h1>
          <div className="banned_img">
            <img src={require("../../Assets/banned.jpg")} alt="" />
          </div>
          <br />
          <p className="ban_para">
            We regret to inform you that your account has been Banned for some
            reason. As a result, you will not be able to access your account or
            any of its associated features. Please contact support for further
            assistance!
          </p>
          <button
            className="sub-bt"
            id="ban-btn"
            onClick={() => handleLogout()}
          >
            GO HOME
          </button>
        </div>
      </div>
    </div>
  );
}

export default Banned;
