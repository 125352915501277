import React from "react";
import Slider from "react-slick";
import "./RegiSlider.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function RegiSlider() {
  var settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    loop: true,
  };
  return (
    <>
      <Slider {...settings}>
        <div className="slider-cont p-3">
          <div className="slider-img">
            <img src={require("../../Assets/Slider/review0.png")} alt="" />
          </div>
          <p className="para p-0 m-0">Trusted Payer</p>
          <h2>Expert Reviews to Help You Make Informed Decisions </h2>
        </div>
        <div className="slider-cont p-3">
          <div className="slider-img">
            <img src={require("../../Assets/Slider/review1.png")} alt="" />
          </div>
          <p className="para p-0 m-0">Trusted Payer</p>
          <h2>Unbiased Reviews about the company </h2>
        </div>
        <div className="slider-cont p-3">
          <div className="slider-img">
            <img src={require("../../Assets/Slider/review2.png")} alt="" />
          </div>
          <p className="para p-0 m-0">Trusted Payer</p>
          <h2>In-Depth Reviews and Analysis </h2>
        </div>
      </Slider>
    </>
  );
}

export default RegiSlider;
