import React, { useState } from "react";
import BaseClient from "../Helpers/BaseClient";
import { APIEndpoints } from "../Constants/APIEndpoints";

const useProfile = () => {
  const [profileLoading, setProfileLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const [contentLoading,setContentLoading] = useState(false)
  const [content,setContent]  = useState(null)
  const [loginData, setLoginData] = useState(null);
  const [bannedStatus, setBannedStatus] = useState(false);

  const updateProfileDetails = async (payload, { onSuccess, onFailed }) => {
    try {
      setProfileLoading(true);

      await BaseClient.post(APIEndpoints.updateUserDetails, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setProfileLoading(false);
    }
  };

  const getAllUSerData = async () => {
    try {
      setProfileLoading(true);
      await BaseClient.get(APIEndpoints.fetchDataOfUser, {
        onSuccess: (res) => {
          if (!Array.isArray(res.data)) return;

          setUserData(res.data);
        },
        onFailed: (err) => {
          console.log("ERROR", err.message);
        },
      });
    } finally {
      setProfileLoading(false);
    }
  };
  const fetchLoginData = async () => {
    await BaseClient.get(APIEndpoints.getSingleUserById, {
      onSuccess: (res) => {
        if (!res.data) return;
        setLoginData(res.data);
        setBannedStatus(false);
      },
      onFailed: (err) => {
        if (err.errorCode === "UBB") {
          setBannedStatus(true);
        }
        console.log("PROBLEM WITH GET USERS", err);
      },
    });
  };
  const updatePassword = async (payload, { onSuccess, onFailed }) => {
    try {
      setProfileLoading(true);
      await BaseClient.post(APIEndpoints.editPassword, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setProfileLoading(false);
    }
  };
  const getAllInformation = async () => {
    try {
      setContentLoading(true);
      await BaseClient.get(APIEndpoints.getAllPageData, {
        onSuccess: (res) => {
          setContent(res.data);
        },
        onFailed: (err) => {
          console.log("ERROR ON GET BUSINESS PROFILE");
        },
      });
    } finally {
      setContentLoading(false);
    }
  };
  return {
    updateProfileDetails,
    userData,
    profileLoading,
    getAllUSerData,
    fetchLoginData,
    loginData,
    getAllInformation,
    contentLoading,
    content,
    updatePassword,
    bannedStatus,
    setBannedStatus,
  };
};

export default useProfile;
