import React, { useContext, useEffect, useState } from "react";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import "./Contact.css";
import BackButton from "../../Components/Backbutton/Backbutton";
import { AppContext } from "../../Context/AppContext";
import toast, { Toaster } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import useContact from "../../Hooks/useContact";
import * as Ai from "react-icons/ai";
import * as Io from "react-icons/io";
import * as Fa from "react-icons/fa";
import Accordion from "../../Components/Accordion/Accordion";
import { PulseLoader } from "react-spinners";

function Contact() {
  const navigate = useNavigate();
  const location = useLocation();
  const { contactUs, loader, routes } = useContact();
  const { content } = useContext(AppContext);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (!routes) return;
    const currentRoute =
      routes && routes.find((route) => route.route === location.pathname);

    if (!currentRoute || !currentRoute.published) {
      navigate("/");
    }
  }, [routes, location]);

  useEffect(() => {
    const data = content != null && content.find((x) => x.pageName === "Contact");
    if (data) {
      setData(data?.contents);
    }
  }, [content]);

  const [contactValues, setContactValues] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [error, setError] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setContactValues((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handlePhone = (e) => {
    const inputValue = e;
    const regex = /^[0-9]*$/;

    if (regex.test(inputValue)) {
      setContactValues((prev) => ({
        ...prev,
        phone: inputValue,
      }));
    }
  };

  const formSubmit = async (e) => {
    e.preventDefault();

    if (
      contactValues.name.length === 0 ||
      contactValues.email.length === 0 ||
      contactValues.phone.length === 0 ||
      contactValues.message.length === 0
    ) {
      setError(true);
      return;
    }

    const payload = contactValues;

    await contactUs(payload, {
      onSuccess: async (res) => {
        toast.success(
          "Thank you for your message! We will get back to you soon."
        );
        setContactValues({
          name: "",
          email: "",
          phone: "",
          message: "",
        });
        setError(false);
      },
      onFailed: (err) => {
        console.log(err.message);
        toast.error(err.message);
      },
    });
  };

  

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <Header />
      <div className="main">
        <Toaster position="top-center" />
      </div>
      <section className="contact-us">
        <div className="container-fluid mb-5">
          <br />
          <div className="container form-fill mt-3">
            <h1 className="head-contact p-3">Feel free to Contact Us!</h1>
            {/* <p className="contact_sub">
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Deserunt
              rerum, ratione natus nulla sed inventore, ullam assumenda iusto
              officiis amet aut vitae.
            </p> */}
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="card contact_info_card">
                  <div className="contact_info">
                    <p>
                      <i>
                        <Io.IoMdMail />
                      </i>{" "}
                      {data?.info?.email ?? "N/A"}
                    </p>
                  </div>
                  <div className="contact_info">
                    <p>
                      <i>
                        <Fa.FaPhoneAlt />
                      </i>{" "}
                      {data?.info?.phone ?? "N/A"}
                    </p>
                  </div>
                  <div className="contact_info">
                    <p>
                      <i>
                        <Fa.FaLocationArrow />
                      </i>{" "}
                      {data?.info?.address ?? "N/A"}
                    </p>
                  </div>
                </div>
                <div className="card py-3 mt-2">
                  <Accordion />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="card">
                  <form
                    onSubmit={(e) => formSubmit(e)}
                    className="contact-form"
                  >
                    <div className="form-group mb-2">
                      <label htmlFor="" className="form-label review-label">
                        Full Name <span className="required">*</span>
                      </label>
                      <div className="review-group">
                        <input
                          type="text"
                          className="form-control review-input"
                          name="name"
                          placeholder="Full Name"
                          autoComplete="off"
                          value={contactValues.name}
                          onChange={handleChange}
                        />
                        <i className="inp_ico">
                          <Ai.AiOutlineUser />
                        </i>
                      </div>
                      {contactValues.name.length == 0 && error && (
                        <span className="err_msg">Your Name is Required!</span>
                      )}
                    </div>

                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label review-label">
                        Email Address <span className="required">*</span>
                      </label>
                      <div className="review-group">
                        <input
                          type="text"
                          className="form-control review-input"
                          name="email"
                          placeholder="Enter your Email Address"
                          value={contactValues.email}
                          onChange={handleChange}
                        />
                        <i className="inp_ico">
                          <Ai.AiOutlineMail />
                        </i>
                      </div>
                      {contactValues.email.length == 0 && error && (
                        <span className="err_msg">
                          Your Mail Address is Required!
                        </span>
                      )}
                    </div>

                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label review-label">
                        Phone Number <span className="required">*</span>
                      </label>
                      <div className="review-group">
                        <input
                          type="text"
                          className="form-control review-input"
                          name="phone"
                          placeholder="Enter your phone number"
                          value={contactValues.phone}
                          onChange={(e) => handlePhone(e.target.value)}
                        />
                        <i className="inp_ico">
                          <Ai.AiOutlinePhone />
                        </i>
                      </div>
                      {contactValues.phone.length == 0 && error && (
                        <span className="err_msg">
                          Phone Number is Required!
                        </span>
                      )}
                    </div>

                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label review-label">
                        Message <span className="required">*</span>
                      </label>
                      <textarea
                        name="message"
                        id=""
                        cols="30"
                        rows="10"
                        className="form-control review-textArea"
                        placeholder="Write your Message"
                        value={contactValues.message}
                        onChange={handleChange}
                      ></textarea>
                      {contactValues.message.length == 0 && error && (
                        <span className="err_msg">Message is Required!</span>
                      )}
                    </div>
                    <div className="text-center">
                      <button
                        className="review-btn review-submit my-3"
                        type="submit"
                      >
                        {loader ? (
                          <PulseLoader color="#fff" size={10} />
                        ) : (
                          "Submit"
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Contact;
