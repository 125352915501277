import React, { useState } from "react";
import { APIEndpoints } from "../Constants/APIEndpoints";
import BaseClient from "../Helpers/BaseClient";

const useReviews = () => {
  const [reviews, setReviews] = useState([]);
  const [reviewLoading, setReviewLoading] = useState(false);

  const getReviewList = async () => {
    try {
      setReviewLoading(true);

      await BaseClient.get(APIEndpoints.getAllReviews, {
        onSuccess: (res) => {
          if (!Array.isArray(res.data)) return;
          setReviews(res.data);
        },
        onFailed: (err) => {
          console.log("REVIEWS NOT FOUND,", err.message);
        },
      });
    } finally {
      setReviewLoading(false);
    }
  };

  return { getReviewList, reviews, reviewLoading };
};

export default useReviews;
