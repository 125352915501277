import React, { useEffect, useState } from "react";
import Header from "../../Components/Header/Header";
import ReactReadMoreReadLess from "react-read-more-read-less";
import Picture from "../../Assets/man.png";
import * as Ai from "react-icons/ai";
import * as Bi from "react-icons/bi";
import * as Hi from "react-icons/hi";
import { useContext } from "react";
import { AppContext } from "../../Context/AppContext";
import toast, { Toaster } from "react-hot-toast";
import Utils from "../../Utils/Utils";
import { useNavigate } from "react-router-dom";
import { Fragment } from "react";
import { useRef } from "react";
import { PulseLoader } from "react-spinners";
import ProfileModal from "./ProfileModal";

function Profile() {
  const navigate = useNavigate();
  const inpRef = useRef();
  const {
    userData,
    getAllUSerData,
    loginData,
    profileLoading,
    updateProfileDetails,
  } = useContext(AppContext);

  const [isEditMode, setIsEditMode] = useState(false);
  const [fileModal, setFileModal] = useState(false);
  const [file, setFile] = useState(null);

  const [formValues, setFormValues] = useState({
    fullName: "",
    email: "",
    phone: "",
    image: null,
  });
  const [showModal, setShowModal] = useState(false);
  const [selectedReview, setSelectedReview] = useState(null);

  useEffect(() => {
    if (loginData) {
      setFormValues({
        fullName: loginData?.fullName,
        email: loginData?.email,
        phone: loginData?.phone,
        image: loginData?.profilepic,
      });

      setFile(loginData?.profilepic);
    }
  }, [loginData]);

  const toggleFileModal = () => {
    setFileModal(!fileModal);
  };

  const handlenavigate = () => {
    navigate("/password");
  };

  const handleFileUploadClick = () => {
    inpRef.current.click();
  };

  const handleFileChange = (file) => {
    const newFile = URL.createObjectURL(file);
    setFile(newFile);
    setFormValues((prev) => ({
      ...prev,
      image: file,
    }));
  };
  const phonePattern = /^(\+\d{1,3})?(?:[0-9\-\(\)\/\.]\s?){6,15}[0-9]{1}$/;

  const handleEditSubmitClick = async (e) => {
    e.preventDefault();

    if (!phonePattern.test(formValues.phone)) {
      toast.error("Please enter a valid phone number");
      return;
    }

    const formData = new FormData();
    formData.append("fullName", formValues.fullName);
    formData.append("email", formValues.email);
    formData.append("phone", formValues.phone);

    if (formValues.image != null) {
      formData.append("image", formValues.image);
    }

    await updateProfileDetails(formData, {
      onSuccess: async (res) => {
        toast.success("Profile Updated Successfully!");
        await getAllUSerData();
        setIsEditMode(false);

        setTimeout(() => {
          window.location.reload();
        }, 800);
      },
      onFailed: (err) => {
        toast.error(
          err?.message + ", Please Try again with correct Credentials!"
        );
        console.error(err);
      },
    });
  };

  const handleReviewModal = (data) => {
    setShowModal(true);
    setSelectedReview(data);
  };

  return (
    <div className="container-fluid">
      <ProfileModal
        showModal={showModal}
        setShowModal={setShowModal}
        reviewData={selectedReview}
      />
      <Toaster position="top-center" reverseOrder={false} />
      <Header />
      <div className="container margin-container">
        <div className="col-md-10 mx-auto">
          <h6 className="p-3">Basic Info</h6>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="card p-3">
                <button
                  className="edit_btn"
                  onClick={() => setIsEditMode(!isEditMode)}
                >
                  {!isEditMode ? (
                    <Fragment>
                      <Bi.BiEditAlt /> Edit
                    </Fragment>
                  ) : (
                    <Fragment>
                      <Ai.AiOutlineCloseCircle />
                    </Fragment>
                  )}
                </button>
                {!isEditMode ? (
                  <div className="row">
                    <div className="col-auto">
                      <div className="profile_">
                        <img
                          src={
                            formValues.image != null
                              ? process.env.REACT_APP_AWS_IMAGE_URL +
                                `userprofilepics/` +
                                formValues?.image
                              : Picture
                          }
                          onClick={toggleFileModal}
                          className="profile-pic"
                          alt="Profile"
                        />
                      </div>
                    </div>
                    <div className="col-auto">
                      <h3 className="profile-name">
                        {formValues?.fullName ?? "N/A"}
                      </h3>
                      <p className="profile_data mb-1">
                        <Hi.HiOutlineMail /> {formValues?.email ?? " "}
                      </p>
                      <p className="profile_data mb-0">
                        <Hi.HiOutlinePhone /> {formValues?.phone ?? " "}
                      </p>
                    </div>
                  </div>
                ) : (
                  <Fragment>
                    <form onSubmit={(e) => handleEditSubmitClick(e)}>
                      <div className="row">
                        <div className="col-auto">
                          <div
                            className="profile_"
                            onClick={handleFileUploadClick}
                          >
                            <button className="edit_photo" type="button">
                              <Ai.AiOutlineCamera />
                            </button>
                            <img
                              src={
                                formValues.image != null &&
                                typeof formValues.image !== "object"
                                  ? process.env.REACT_APP_AWS_IMAGE_URL +
                                    `userprofilepics/` +
                                    formValues?.image
                                  : file != null
                                  ? file
                                  : Picture
                              }
                              className="profile-pic"
                              alt="Profile"
                            />
                          </div>
                          <input
                            type="file"
                            name=""
                            id=""
                            onChange={(e) =>
                              handleFileChange(e.target.files[0])
                            }
                            className="file_upload"
                            ref={inpRef}
                          />
                        </div>
                        <div className="col-auto">
                          <div className="form-group">
                            <label
                              htmlFor="Privilege Name"
                              className="review-label"
                            >
                              Name
                            </label>
                            <div className="review-group group-in">
                              <input
                                name="fullName"
                                type="text"
                                id=""
                                className="review-input"
                                value={formValues?.fullName ?? " "}
                                onChange={(e) =>
                                  setFormValues((prev) => ({
                                    ...prev,
                                    fullName: e.target.value,
                                  }))
                                }
                              />
                            </div>
                          </div>
                          <div className="form-group mt-2">
                            <label
                              htmlFor="Privilege Name"
                              className="review-label"
                            >
                              Email
                            </label>
                            <div className="review-group group-in">
                              <input
                                name="email"
                                type="email"
                                id=""
                                className="review-input"
                                value={formValues?.email ?? " "}
                                onChange={(e) =>
                                  setFormValues((prev) => ({
                                    ...prev,
                                    email: e.target.value,
                                  }))
                                }
                              />
                            </div>
                          </div>
                          <div className="form-group mt-2">
                            <label
                              htmlFor="Privilege Name"
                              className="review-label"
                            >
                              Phone
                            </label>
                            <div className="review-group group-in">
                              <input
                                name="phone"
                                type="phone"
                                id=""
                                className="review-input"
                                value={formValues?.phone ?? " "}
                                onChange={(e) =>
                                  setFormValues((prev) => ({
                                    ...prev,
                                    phone: e.target.value,
                                  }))
                                }
                              />
                            </div>
                            <button
                              className="reset-wr mt-2"
                              onClick={() => handlenavigate()}
                              type="button"
                            >
                              Change Password?
                            </button>
                          </div>
                          <button
                            type="submit"
                            className="submit_btn"
                            disabled={profileLoading}
                          >
                            {!profileLoading ? (
                              "Save"
                            ) : (
                              <PulseLoader color="#fff" size={10} />
                            )}
                          </button>
                        </div>
                      </div>
                    </form>
                  </Fragment>
                )}
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 position-relative">
              <button
                className="write_review"
                onClick={() => navigate("/register")}
              >
                <Bi.BiPencil /> Write a Review
              </button>
            </div>
          </div>
          <div className="row">
            <h6 className="mt-3 mb-3">Reviews</h6>
            {userData != null &&
              Array.isArray(userData) &&
              userData.toReversed().map((data, index) => {
                console.log(data);

                return (
                  <>
                    <div className="col-md-6" key={index}>
                      <div>
                        <div className="card review-card">
                          <button
                            className="action_btn"
                            onClick={() => handleReviewModal(data)}
                          >
                            <Ai.AiOutlineEye /> Actions
                          </button>
                          <div className="verified_customer_section">
                            <div className="image_review">
                              <div className="customer_name_review_status">
                                <p
                                  className="comp-name"
                                  title={data?.businessName}
                                >
                                  {data.businessName ?? "N/A"}
                                </p>
                                <p className="comp_category">
                                  <span>
                                    {data?.companyConnection ?? "N/A"}
                                  </span>
                                  <p id="info-date">
                                    <Ai.AiFillCalendar />{" "}
                                    <span>
                                      {data.createdAt != null
                                        ? Utils.formatDate(data.createdAt)
                                        : "N/A"}
                                    </span>
                                  </p>
                                </p>
                              </div>
                            </div>

                            <div
                              className="cmnt_wrapper"
                              style={{ height: "100%" }}
                            >
                              <div className="d-flex">
                                <div className="customer_name">
                                  {data.anonymous == true && (
                                    <p className="an-msg">Anonymous</p>
                                  )}
                                </div>
                              </div>

                              <p>
                                <ReactReadMoreReadLess
                                  charLimit={110}
                                  readMoreText={"Read more"}
                                  readLessText={"Read less"}
                                >
                                  {data?.protest ?? "N/A"}
                                </ReactReadMoreReadLess>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            {userData != null && userData.length == 0 && (
              <h1 className="placeholder_">No Reviews</h1>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
