import React, { useContext, useEffect, useState } from "react";
import Header from "../../Components/Header/Header";
import team from "../../Assets/team.jpg";
import Footer from "../../Components/Footer/Footer";
import "./About.css";
import BackButton from "../../Components/Backbutton/Backbutton";
import { AppContext } from "../../Context/AppContext";
import { useLocation, useNavigate } from "react-router-dom";
function About() {
  const navigate = useNavigate();
  const location = useLocation();

  const { content, routes } = useContext(AppContext);
  const [aboutData, setAboutData] = useState(null);

  useEffect(() => {
    if (!routes) return;

    const currentRoute =
      routes && routes.find((route) => route.route === location.pathname);
    if (!currentRoute) return;

    // debugger;
    if (
      !currentRoute ||
      currentRoute.published === false ||
      (currentRoute && currentRoute?.route != location.pathname)
    ) {
      navigate("/");
    }
  }, [routes, location]);

  useEffect(() => {
    const data = content != null && content.find((x) => x.pageName === "About");
    if (data) {
      setAboutData(data);
    }
  }, [content]);

  return (
    <div>
      <div className="container-fluid backgrd">
        <Header />
        <div className="main">
          <div className="container">
            <div className="nav-button">{/* <BackButton /> */}</div>
            <div className="about-second">
              <div className="boxes">
                <div className="text-side">
                  <h1>Trusted Payer</h1>
                </div>
                <div className="image-side">
                  <img src={team} alt="" className="about-img" />
                </div>
              </div>

              <div className="about-content">
                {aboutData?.contents != null &&
                  aboutData?.contents?.aboutParagraphs != null &&
                  aboutData?.contents.aboutParagraphs?.map((data, index) => {
                    return <p>{data?.value ?? "N/A"}</p>;
                  })}
              </div>
            </div>
          </div>
          <div className="container last">
            <div className="back-box">
              <div className="contents card">
                <div className="row">
                  <div className="col-md-6">
                    <h1 className="head-card">
                      {aboutData?.contents?.aboutHead ?? "N/A"}
                    </h1>
                    <p className="card-para">
                      {aboutData?.contents?.aboutPara1 ?? "N/A"}
                    </p>
                  </div>
                  <div className="col-md-6">
                    <p className="card-para">
                      {aboutData?.contents?.aboutPara2 ?? "N/A"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div></div>
      </div>
      <Footer />
    </div>
  );
}

export default About;
